import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

const styles = ({ uikit }) => ({
  paragraph: {
    fontFamily: 'Lato',
    fontSize: 14,
    color: uikit.paragraph.text,
    marginTop: 16,
  },
})

class Paragraph extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.paragraph} style={this.props.style}>
        {this.props.children}
      </div>
    )
  }
}

Paragraph.propTypes = {
  classes: PropTypes.object,
  style: PropTypes.object,
}

export default withRouter(withStyles(styles)(Paragraph))
