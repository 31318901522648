import styled from 'styled-components'

export const TextTogglerContainer = styled.div`
  position: relative;
  border: 1px solid var(--color-issabeline-grey);
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  margin-top: 10px;
`
