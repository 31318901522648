import React from 'react'
import * as S from './CustomRadioButton.style'

interface Props {
  options: string[]
  selected: string
  onChange: (o: string) => void
}

export const CustomRadioButton: React.FC<Props> = ({ options, selected, onChange }) => {
  return (
    <S.CustomRadioButton>
      {options.map(option => (
        <S.Tab key={option} isSelected={selected === option} onClick={() => onChange(option)}>
          {option}
        </S.Tab>
      ))}
    </S.CustomRadioButton>
  )
}
