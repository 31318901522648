import React, { useEffect, useMemo, useRef, useState } from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'

import { customStyles } from './styles'
import classes from './styles.module.scss'
import {OptionItemType} from '../../models/MessageTypes'

interface Props {
  title?: string
  flows: any
  values: string[] | number[]
  error?: boolean
  errorMessage?: string
  dataAutotest?: string
  onFocus?(event): void
  onChange(value: OptionItemType): void
}

const AtomMultiSelect: React.FC<Props> = ({
  error,
  errorMessage,
  title,
  flows,
  values,
  onFocus,
  onChange,
  dataAutotest
}) => {
  const [options, setOptions]: [OptionItemType[], any] = useState([])
  const selectContainer = useRef(null)

  const atomValues = useMemo(() => {
    return values.map(value => options.find(({ value: val, label }) => val == value || label == value))
  }, [values, options])

  useEffect(() => {
    if (flows) {
      const options: OptionItemType[] = []

      flows.forEach(({ atoms }: any) => {
        if (atoms) {
          atoms.forEach(({ name, id }: any) => {
            options.push({ label: name, value: id })
          })
        }
      })

      setOptions(options)
    }
  }, [flows])

  useEffect(() => {
    if (selectContainer?.current) {
      selectContainer.current?.querySelectorAll('input').forEach(input => {
        input.setAttribute('data-autotest', dataAutotest)
      })
    }
  }, [selectContainer.current])

  const handleChange = value => {
    onChange(value)
  }

  return (
    <span ref={selectContainer}>
      {title && <p className={classes.title}>{title}</p>}
      <Select
        isMulti
        isClearable={false}
        styles={customStyles}
        options={options}
        value={atomValues}
        maxMenuHeight={200}
        minMenuHeight={200}
        menuPlacement={'auto'}
        error={error}
        onChange={handleChange}
        onFocus={onFocus}
        placeholder={'Select atom'}
      />
      {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
    </span>
  )
}

const mapStateToProps = (state: { flows: any }) => ({
  flows: state.flows,
})

export default connect(mapStateToProps)(AtomMultiSelect)
