import React from 'react'
import Modal from '@material-ui/core/Modal'

import SubmitButton from 'uiKit/buttons/SubmitButton'
import * as S from './DeleteAtomModal.style'

interface Props {
  atom: any
  handleClose: () => void
  handleDelete: () => void
}

export const DeleteAtomModal: React.FC<Props> = ({ atom, handleClose, handleDelete }) => {
  return (
    <Modal
      open={true}
      onClose={handleClose}>
      <S.Container>
        <S.Title>Are you sure you want to delete "{atom.name}" atom?</S.Title>
        <S.ButtonsWrap>
          <SubmitButton onClick={handleDelete} title="Delete" />
          <SubmitButton type="primary_with_border" onClick={handleClose} title="Cancel" />
        </S.ButtonsWrap>
      </S.Container>
    </Modal>
  )
}
