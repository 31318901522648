export const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: 14,
    color: 'var(--color-text-primary)',
  },
  showItemsBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginLeft: 16,
    width: 98,
    borderRadius: 10,
  },
  pages: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,

    '& button': {
      background: 'transparent',
      border: 0,
      cursor: 'pointer',

      '&:hover svg path': {
        stroke: 'var(--color-button-primary)',
      },
      '&:disabled:hover svg path': {
        stroke: 'var(--color-spun-pearl-grey)',
      },

      '&:disabled': {
        cursor: 'default',
      },
    },
    '& input': {
      margin: '0 8px',
      fontSize: 14,
      color: 'var(--color-button-primary)',
      borderRadius: 10,
      width: 38,
      height: 38,
      border: '1px solid var(--color-mischka-grey)',
      textAlign: 'center',
      '-moz-appearance': 'textfield',
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&:focus': {
        outline: 'none',
        borderColor: '#cccccc',
      }
    },

    '& p': {
      margin: '0 8px',
    },
  },
})
