import { BACKSPACE_KEY, UNDERSCORE_KEY, CLOSING_BRACKET_KEY } from 'constants/keyCodes'
import { LOWERCASE_LETTERS } from 'constants/letters'

export const handleSpace = (event, isUseAttribute) => {
  const key = event.keyCode || event.charCode
  if (
    !LOWERCASE_LETTERS.includes(event.key) &&
    key !== BACKSPACE_KEY &&
    !([UNDERSCORE_KEY, CLOSING_BRACKET_KEY].includes(key) && event.shiftKey) &&
    isUseAttribute
  ) {
    event.preventDefault()
  }
}
