import React, { FC, useState } from 'react'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Reducers from 'reducers'
import { HOME_PATH } from 'configs'
import Table from 'uiKit/table/Table'
import TableRow from 'uiKit/table/TableRow'
import TableCell from 'uiKit/table/TableCell'
import { ExternalLinkIcon } from 'uiKit/icons/ExternalLinkIcon'
import { KnowledgeBaseSourceLinkIcon } from 'uiKit/icons/KnowledgeBaseSourceLinkIcon'
import { AlertTriangle } from 'uiKit/icons/AlertTriangle'
import { KnowledgeBaseSubTabMap, SourceTypeMap, SourceTypes } from 'tabs/nlp/constants'
import { KnowledgeBaseReply } from 'tabs/nlp/api/websiteKnowledgeBase'

import * as S from './KnowledgeBaseRepliesTable.style'
import classes from './styles.module.scss'
import { EditKnowledgeBaseReply, TextInsertSource } from './EditKnowledgeBaseReply'
import { EditTextInsert } from '../EditTextInsert/EditTextInsert'
import { StopGeneration } from 'uiKit/icons/StopGeneration'

interface KnowledgeBaseRepliesTableProps {
  knowledgeBaseReplies: KnowledgeBaseReply[]
  botId: number
  page: number
  fetchKnowledgeReplies: (page: number) => void
}

const columnNames = [
  { title: 'User phrase', maxWidth: 300 },
  { title: 'AI Knowledge reply', maxWidth: 300 },
  { title: 'Source', maxWidth: 200 },
  { title: 'Sent at', maxWidth: 65 },
  { title: '', maxWidth: 75 },
]

const getTooltipTitle = (sourceType: SourceTypes | undefined, source?: string | undefined): string => {
  const sourceTypeToTitleMap = {
    [SourceTypeMap.TEXT]: 'Text source',
    [SourceTypeMap.WEBSITE]: source || 'Website source',
    [SourceTypeMap.FILE]: 'File source',
  }

  return (
    sourceTypeToTitleMap[sourceType] ||
    'The response is based not on trained sources but on the custom prompt info and/or general GPT knowledge'
  )
}

const getSourceColumnLabel = (knowledgeBaseReply: KnowledgeBaseReply): string => {
  if (knowledgeBaseReply.sourceType === SourceTypeMap.TEXT) {
    return knowledgeBaseReply.sourceName || knowledgeBaseReply.source || 'Untitled'
  } else if (knowledgeBaseReply.sourceType === SourceTypeMap.WEBSITE) {
    return new URL(knowledgeBaseReply.source).pathname
  } else if (knowledgeBaseReply.sourceType === SourceTypeMap.FILE) {
    return knowledgeBaseReply.sourceName || knowledgeBaseReply.source
  } else if (!knowledgeBaseReply.isFailed) {
    return 'General knowledge'
  }
}
const getSourceColumnBackground = (sourceType: SourceTypes, isFailed: boolean, isStoppedByUser?: boolean): string => {
  if (isFailed || isStoppedByUser) return 'none'
  if (!sourceType) return 'var(--color-half-dutch-white)'

  const sourceTypeToColorMap = {
    [SourceTypeMap.TEXT]: 'var(--color-peppermint)',
    [SourceTypeMap.WEBSITE]: 'var(--color-light-peach)',
    [SourceTypeMap.FILE]: 'var(--color-tropical-blue)',
  }

  return sourceTypeToColorMap[sourceType]
}

type ReplyWithTextSource = TextInsertSource & { replyId: number }

const getRowBackground = (isFailed: boolean, isStoppedByUser?: boolean) => {
  const defaultStyle = {}
  const failedRowStyles = { background: 'var(--color-peach-schnapps)' }
  const stoppedByUserRowStyles = { background: 'var(--color-egg-white)' }

  return isStoppedByUser ? stoppedByUserRowStyles : isFailed ? failedRowStyles : defaultStyle
}

const KnowledgeBaseRepliesTableContainer: FC<KnowledgeBaseRepliesTableProps & RouteComponentProps> = ({
  knowledgeBaseReplies,
  botId,
  page,
  fetchKnowledgeReplies,
}) => {
  const botTimezone = useSelector((state: ReturnType<typeof Reducers>) => state.activeBot.timezone)
  const [replyWithSource, setReplyWithSource] = useState<ReplyWithTextSource>(null)

  const handleOpenSupportPage = (e: MouseEvent, supportPageURL: string) => {
    e.preventDefault()
    window.open(supportPageURL, '_blank')
  }

  const getSourceColumnHandler = (knowledgeBaseReply: KnowledgeBaseReply): (() => void) => {
    const sourceTypeToHandlerMap = {
      [SourceTypeMap.WEBSITE]: () => {
        window.open(knowledgeBaseReply.source, '_blank')
      },
      [SourceTypeMap.FILE]: () => {
        window.open(knowledgeBaseReply.source, '_blank')
      },
      [SourceTypeMap.TEXT]: () => {
        const pathParams = `${KnowledgeBaseSubTabMap.TEXT}/${knowledgeBaseReply.sourceId}`
        window.open(`${window.location.origin}/admin/bot/${botId}/nlp/ai-knowledge/${pathParams}`, '_blank')
      },
    }

    return sourceTypeToHandlerMap[knowledgeBaseReply.sourceType]
  }

  const handleEditReply = ({ sourceId, title, text, replyId }: ReplyWithTextSource) => {
    setReplyWithSource({ sourceId, title, text, replyId })
  }

  return (
    <>
      <Table titles={columnNames}>
        {knowledgeBaseReplies?.map((knowledgeBaseReply, index) => {
          const supportPageURL = `${HOME_PATH}/bot/${botId}/support/${knowledgeBaseReply.userId}`
          const isReplySucceeded = !knowledgeBaseReply.isFailed && !knowledgeBaseReply.isStoppedByUser
          return (
            <TableRow
              style={{
                alignItems: 'center',
                ...getRowBackground(knowledgeBaseReply.isFailed, knowledgeBaseReply.isStoppedByUser),
              }}
              key={knowledgeBaseReply.id}
              index={index}>
              <TableCell style={{ maxWidth: 300 }}>
                <S.MessageWrap>{knowledgeBaseReply.userPhrase}</S.MessageWrap>
              </TableCell>
              <TableCell style={{ maxWidth: 300 }}>
                {knowledgeBaseReply.isStoppedByUser ? (
                  <div>
                    <S.MessageWrap>{knowledgeBaseReply.odinReply}</S.MessageWrap>
                    {knowledgeBaseReply.odinReply ? <br /> : null}
                    <span style={{ wordBreak: 'break-word' }}>
                      <StopGeneration /> &nbsp;Reply generation was stopped or interrupted.
                    </span>
                  </div>
                ) : knowledgeBaseReply.isFailed ? (
                  <div>
                    <S.MessageWrap>{knowledgeBaseReply.odinReply}</S.MessageWrap>
                    {knowledgeBaseReply.odinReply ? <br /> : null}
                    <span>
                      <AlertTriangle /> &nbsp;An error occurred while generating reply.
                    </span>
                  </div>
                ) : (
                  <S.MessageWrap>{knowledgeBaseReply.odinReply}</S.MessageWrap>
                )}
              </TableCell>
              <TableCell style={{ maxWidth: 200 }}>
                {isReplySucceeded && (
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={getTooltipTitle(knowledgeBaseReply.sourceType, knowledgeBaseReply.source)}
                    placement="top">
                    <S.SourceWrap
                      onClick={getSourceColumnHandler(knowledgeBaseReply)}
                      backgroundColor={getSourceColumnBackground(
                        knowledgeBaseReply.sourceType,
                        knowledgeBaseReply.isFailed,
                        knowledgeBaseReply.isStoppedByUser,
                      )}>
                      <span>{getSourceColumnLabel(knowledgeBaseReply)}</span>
                      {knowledgeBaseReply.sourceType === SourceTypeMap.TEXT ? (
                        <S.LinkIconWrap>
                          <KnowledgeBaseSourceLinkIcon />
                        </S.LinkIconWrap>
                      ) : (
                        knowledgeBaseReply.source &&
                        knowledgeBaseReply.sourceType && (
                          <S.LinkIconWrap>
                            <KnowledgeBaseSourceLinkIcon />
                          </S.LinkIconWrap>
                        )
                      )}
                    </S.SourceWrap>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell style={{ maxWidth: 65 }}>
                {moment(knowledgeBaseReply.sentAt).add(botTimezone, 'hours').format('DD.MM.YY HH:mm A')}
              </TableCell>
              <TableCell style={{ maxWidth: 75, maxHeight: 30, justifyContent: 'space-evenly' }}>
                {!knowledgeBaseReply.isFailed && (
                  <EditTextInsert
                    onClick={() =>
                      handleEditReply({
                        sourceId: knowledgeBaseReply.improvementSourceId,
                        title: knowledgeBaseReply.userPhrase,
                        text: knowledgeBaseReply.odinReply,
                        replyId: knowledgeBaseReply.id,
                      })
                    }
                    tooltipTitle="Improve AI knowledge"
                    tooltipPlacement="top"
                    tooltipClasses={{ tooltip: classes.tooltip }}
                    wasEdited={Boolean(knowledgeBaseReply.improvementSourceId)}
                  />
                )}
                {/* <Tooltip placement="top" title="Open conversation" classes={{ tooltip: classes.tooltip }}> */}
                <S.ExternalLinkWrap
                  href={supportPageURL}
                  onClick={(e: MouseEvent) => handleOpenSupportPage(e, supportPageURL)}>
                  <ExternalLinkIcon />
                </S.ExternalLinkWrap>
                {/* </Tooltip> */}
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
      {Number.isInteger(replyWithSource?.replyId) && (
        <EditKnowledgeBaseReply
          botId={botId}
          isOpen
          onClose={() => setReplyWithSource(null)}
          page={page}
          fetchKnowledgeReplies={fetchKnowledgeReplies}
          replyId={replyWithSource.replyId}
          improvementSourceId={replyWithSource.sourceId}
          inputTitle={replyWithSource.title}
          textAreaContent={replyWithSource.text}
        />
      )}
    </>
  )
}

export const KnowledgeBaseRepliesTable = withRouter(KnowledgeBaseRepliesTableContainer)
