import classes from './styles.module.scss'
import React, { useEffect, useState, SetStateAction } from 'react'
import { connect } from 'react-redux'

import LoaderOverlay from '../../../../../uiKit/loaders/loaderOverlay'
import Input from '../../../../../uiKit/inputs/Input'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import { isStringEmpty } from '../../../../../helpers/isStringEmpty'
import { updateTelegramCredentials } from '../../../api/telegram'
import { alertSuccess, alertError } from '../../../../../api'

interface Props {
  activeBot: any
}

const Telegram: React.FC<Props> = ({ activeBot }) => {
  const [accessToken, setAccessToken] = useState('')
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setAccessToken(activeBot.telegramConfigs?.accessToken)
  }, [activeBot.telegramConfigs?.accessToken])

  const handleSubmit = async() => {
    if (isValidFields()) {
      const data = { accessToken, id: activeBot.id }
      setLoading(true)
      try {
        const response = await updateTelegramCredentials(activeBot.id, data)
        if (response.error?.message) {
          alertError('Something went wrong. Please check Access Token')
        } else {
          alertSuccess('Telegram bot connected successfully')
        }
      } catch (e) {
        setAccessToken('')
        alertError('Failed, try it later')
      }
      setLoading(false)
    }
  }

  const handleChange = (event: { target: { value: SetStateAction<null> } }) => {
    setAccessToken(event.target.value)
  }

  const isValidFields = () => {
    const error = isStringEmpty(accessToken)
    setIsError(isStringEmpty(accessToken))

    return !error
  }

  return (
    <>
      <div className={classes.inputWrap}>
        <Input
          error={isError}
          title="Access Token"
          value={accessToken || ''}
          maxLength={255}
          handleEnterPress={handleSubmit}
          placeholder="Enter Telegram Access Token"
          onChange={handleChange}
        />
        <p className={classes.description}>
          Guide to retrieve Access Token and create a new bot is{' '}
          <a href="https://core.telegram.org/bots#6-botfather" target="_blank" rel="noreferrer">
            here
          </a>
        </p>
        {isError && <p className={classes.errorAlert}>Telegram Access Token cannot be an empty field!</p>}
      </div>
      <div className={classes.buttonHolder}>
        <SubmitButton
          title="Connect"
          type={'secondary'}
          size="sm"
          onClick={handleSubmit}
          disabled={!accessToken?.length}/>
      </div>
      {loading && <LoaderOverlay />}
    </>
  )
}

const mapStateToProps = (state: { activeBot: any }) => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(Telegram)
