export const SPACE_KEY = 32
export const ARROW_DOWN_KEY = 40
export const ARROW_UP_KEY = 38
export const ARROW_LEFT_KEY = 37
export const ARROW_RIGHT_KEY = 39
export const ENTER_KEY = 13
export const DELETE_KEY = 46
export const BACKSPACE_KEY = 8
export const ZERO_KEY = 48
export const NINE_KEY = 57
export const UNDERSCORE_KEY = 189
export const CLOSING_BRACKET_KEY = 221
