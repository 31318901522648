import api from 'services/api'
import { BASE_URL } from 'configs'
import { alertError } from 'api'
import { saveNlpRequests } from '../actions/nlpRequests'
import { addQueryStringParameter } from '../../../helpers'

export function getNlpRequest(
  botId: number,
  page: number,
  size: number,
  language: string,
  startDate: string,
  endDate: string,
  intentId: string,
  debouncedSearch: string,
) {
  let url = `${BASE_URL}/bot/${botId}/nlpRequest?page=${page}&size=${size}&from=${startDate}&to=${endDate}`

  if (language && language !== 'all') url = addQueryStringParameter(url, 'language', language)

  if (intentId && intentId !== 'all') url = addQueryStringParameter(url, 'intentId', intentId)

  if (debouncedSearch) url = addQueryStringParameter(url, 'searchQuery', debouncedSearch)

  return api
    .get(`${url}`)
    .then(res => {
      const data = {
        data: res.content,
        totalCount: res.totalElements,
      }
      saveNlpRequests(data)
    })
    .catch(() => {
      alertError('Sorry but something going wrong at getting training data please ping support!')
    })
}
