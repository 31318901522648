import styled from 'styled-components'

const InputWrap = styled.div`
  margin-bottom: 32px;
  position: relative;
`

const ErrorAlert = styled.p`
  color: var(--color-text-error);
  margin-bottom: -10px;
  font-size: 12px;
  position: absolute;
  z-index: 2;
  background-color: var(--color-white);
  padding-top: 4px;
`

const MaxLengthAlert = styled.p`
  color: var(--color-spun-pearl-grey);
  margin-bottom: -10px;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  background-color: var(--color-white);
`

const EllipsisText = styled.div`
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
`

const IconWrap = styled.span`
  cursor: pointer;
  padding-right: 6px;
`

const ButtonWrap = styled.div`
  position: relative;
  height: 80px;
  padding-bottom: 20px;
`

export { InputWrap, ErrorAlert, MaxLengthAlert, EllipsisText, IconWrap, ButtonWrap }
