export const styles = theme => ({
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '8px 16px',
  },
  connectedPageTooltip: {
    minWidth: '180px',
    maxWidth: '300px',
    left: '24px !important',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    opacity: '1 !important',
    '&:after': {
      marginLeft: '-68px !important',
    },
  },
})
