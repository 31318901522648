export const styles = () => ({
  input: {
    '& > input': {
      opacity: 0,
      width: 0,
      height: 0,
      position: 'absolute',
    },
    '& > label': {
      cursor: 'pointer',
      fontSize: 14,
      padding: '8px 16px',
      border: '1px solid var(--color-button-primary)',
      borderRadius: 4,
      color: 'var(--color-button-primary)',
      width: 'fit-content',
      fontWeight: 500,
      backgroundColor: 'var(--color-white)',
      margin: '30px 0px 0px 0px',
      '& > span': {
        margin: '0px 6px 0px 0px',
      },
    },
  },
})
