import React from 'react'
import uuid from 'uuid'
import classes from './styles.module.scss'

const GradientBar = ({ step, gridItemsCount }) => {
  const colors = []

  for (let i = 0; i < gridItemsCount; i++) {
    colors.push({
      start: `rgba(var(--color-analytics-r), var(--color-analytics-g), var(--color-analytics-b), ${0.2 * i})`,
      end: `rgba(var(--color-analytics-r), var(--color-analytics-g), var(--color-analytics-b), ${0.2 * (i + 1)})`,
    })
  }

  return (
    <div className={classes.gradientBarWrapper}>
      <div className={classes.gradientBar}>
        {colors.map((color, index) => (
          <div key={uuid()} className={classes.gradientBarColumn}>
            <div
              style={{
                background: `linear-gradient(90deg, ${color.start}, ${color.end})`,
              }}
              className={classes.gradientBarItem}
            />
            <div className={classes.gradientBarItemText}>{(index + 1) * step}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default GradientBar
