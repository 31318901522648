import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'

import { cellStyles, styles } from './styles'

interface Props {
  classes: any
}

const View:React.FC<Props> = props => {
  const { classes } = props

  return (
    <TableHead className={classes.head}>
      <TableRow className={classes.headRow}>
        <TableCell style={cellStyles.image} />
        <TableCell style={cellStyles.name}>Page name</TableCell>
        <TableCell style={cellStyles.languages}>Default language</TableCell>
        <TableCell style={cellStyles.connectedBy}>Connected by</TableCell>
        <TableCell style={cellStyles.menu} />
      </TableRow>
    </TableHead>
  )
}

export const FacebookActivePagesTableHead = withStyles(styles)(View)
