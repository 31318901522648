import React, {FC} from 'react'

import SecondaryButton from '../../../../uiKit/buttons/SecondaryButton/SecondaryButton'
import Label from '../../../../uiKit/texts/Label'

import classes from './styles.module.scss'

interface Props {
  onClick: () => void
  showButton: boolean
}

const FunnelModalFooter: FC<Props> = ({onClick, showButton}) => {
  return (
    <div className={classes.container}>
      {showButton && <SecondaryButton
        styles={{
          color: 'var(--color-button-primary)',
          borderColor: 'var(--color-button-primary)',
        }}
        plusIconColor='var(--color-button-primary)'
        title="Add new step"
        onClick={onClick}
      />}
      <Label>10 steps max allowed</Label>
    </div>
  )
}

export default FunnelModalFooter
