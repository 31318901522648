export const customStyles = {
  control: (provided, state) => {
    const error = state.selectProps.error

    return {
      ...provided,
      border: error ? '1px solid red' : '1px solid var(--color-mischka-grey)',
      outline: 'none',
      boxShadow: 'none',
      borderRadius: 10,
      padding: '2px 6px',
      '&:hover': {
        border: error ? '1px solid red' : '1px solid rgba(53, 64, 82, 0.5)',
        boxShadow: 'none',
      },
    }
  },
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'var(--color-oxford-blue)',
    fontSize: 14,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: 14,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
      color: 'var(--color-text-primary)',
      '&:hover': {
        fontSize: 14,
        backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
        color: 'var(--color-text-primary)',
      },
    }
  },
  menu: provided => ({
    ...provided,
    padding: 0,
    overflow: 'hidden',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgba(53, 64, 82, 0.5)',
    fontSize: 14,
    width: 'max-content',
  }),
  multiValue: provided => ({
    ...provided,
    borderRadius: 4,
    backgroundColor: 'var(--color-button-secondary)',
    '& > div': {
      color: 'var(--color-text-on-secondary)',
    },
    '& > div: hover': {
      backgroundColor: 'var(--color-button-secondary)',
      color: 'var(--color-text-on-secondary)',
    }
  }),
}
