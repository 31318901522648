import React, { useEffect, useRef, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import { TrashIcon } from '../icons/TrashIcon'
import ReactTooltip from 'react-tooltip'

const AttributeSelectItem = props => {
  const {
    classes,
    attribute,
    handleUpdateAttributeName,
    handleUpdateAttributeValue,
    handleEnterAttributeName,
    handleFocus,
    handleDeleteAttribute,
    focus,
    error,
    isRtl,
    index
  } = props
  const [isExtraWidth, setIsExtraWidth] = useState(false)
  const currentAttribute = useRef(null)
  const attributeContainer = useRef(null)

  useEffect(() => {
    setIsExtraWidth(currentAttribute?.current?.offsetWidth >= attributeContainer?.current?.offsetWidth)
  }, [focus])

  return (
    <div className={classes.attributeWrap} key={attribute.id} data-autotest={`attribute-wrap-modal-${index}`}>
      <div className={classes.attributeNameWrap} ref={attributeContainer}>
        {focus === attribute.id || !attribute.name ? (
          <div className={classes.inputWrap}>
            <input
              value={attribute.name}
              className={classes.input}
              style={{
                borderColor: attribute.isNew && !attribute.isValid && 'red',
              }}
              onChange={e => handleUpdateAttributeName(e, attribute.id)}
              onKeyPress={e => handleEnterAttributeName(e)}
              onBlur={() => handleFocus(false)}
              placeholder={'none'}
              autoFocus={true}
              data-autotest={`attribute-name-modal-input-${index}`}
            />
            {error && <p className={classes.warning}>{error}</p>}
          </div>
        ) : (
          <p
            className={classes.attributeName}
            data-tip={attribute.name}
            data-for={attribute.name}
            ref={currentAttribute}
            onClick={() => attribute.isNew && handleFocus(attribute.id)}
            data-autotest={`attribute-name-modal-${index}`}>
            {attribute.name}
            {isExtraWidth && (
              <ReactTooltip
                className={classes.tooltip}
                effect="solid"
                place="top"
                type="dark"
                scrollHide={true}
                id={attribute.name}
              />
            )}
          </p>
        )}
      </div>

      <input
        value={attribute.defaultValue || ''}
        className={classes.input}
        style={{ marginLeft: 8 }}
        placeholder={'none'}
        dir={isRtl ? 'rtl' : 'ltr'}
        data-autotest={`attribute-value-modal-${index}`}
        onChange={e => handleUpdateAttributeValue(e, attribute.id)}
      />
      {attribute.isNew && (
        <div className={classes.iconWrap} onClick={() => handleDeleteAttribute(attribute.id)}>
          <TrashIcon />
        </div>
      )}
    </div>
  )
}
AttributeSelectItem.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(AttributeSelectItem)
