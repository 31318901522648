import { TrainingContainer } from './TrainingContainer'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  nlpRequests: state.nlpRequests,
  intents: state.intents,
})

export default connect(mapStateToProps)(TrainingContainer)
