import api from 'services/api'
import { BASE_URL } from 'configs'

export const createApiKey = (botId: number, name: string) => {
  return api.post(`${BASE_URL}/bot/${botId}/keys`, { name })
}

export const getApiKeys = (botId: number) => {
  return api.get(`${BASE_URL}/bot/${botId}/keys`)
}

export const deleteApiKey = (botId: number, selectedApiKey: string) => {
  return api.delete(`${BASE_URL}/bot/${botId}/keys/${selectedApiKey}`)
}
