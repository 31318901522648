import SendIcon from '../../../../uiKit/icons/SendIcon'
import { CrossIcon } from '../../../../uiKit/icons/CrossIcon'
import { Next } from '../../../../uiKit/slider/Next'

import classes from './styles.module.scss'
import {LanguageConfigsType} from '../../../../models/WidgetSettingsTypes'
import React from 'react'

interface Props {
  languageConfigs: LanguageConfigsType
}

const WidgetChatScreen: React.FC<Props> = ({ languageConfigs }) => {
  const { imageUrl, color, widgetName, inputPlaceholder } = languageConfigs

  return (
    <div className={classes.container}>
      <div className={classes.header} style={{ backgroundColor: color }}>
        <div className={classes.nameWrap}>
          <img src={imageUrl} alt="logo" className={classes.logo} />
          <p className={classes.name}>{widgetName}</p>
        </div>
        <CrossIcon color={'var(--color-white)'} />
      </div>
      <div className={classes.body}>
        <div className={classes.row}>
          <p className={classes.message1}>
            Hey there! I'm an Abarth bot and I'm gonna help you to find your perfect car!
          </p>
        </div>
        <div className={classes.row}>
          <img src={imageUrl} alt="logo" className={classes.messageLogo} />
          <p className={classes.message2}>Let's take a short funny quiz to find out what Abarth car are you 😉</p>
        </div>
        <div className={classes.row}>
          <p className={classes.quickReply} style={{ color, borderColor: color }}>
            Let’s start!
          </p>
        </div>
        <div className={classes.row}>
          <img src={imageUrl} alt="logo" className={classes.messageLogo} />
          <p className={classes.message2}>Excited? Me too! So, the first question - what's your mood right now?</p>
        </div>
        <div className={classes.row}>
          <p className={classes.quickReply} style={{ color, borderColor: color }}>
            Exciting!
          </p>
          <p className={classes.quickReply} style={{ color, borderColor: color }}>
            Superb!
          </p>
          <p className={classes.quickReply} style={{ color, borderColor: color }}>
            Chilling!
          </p>
          <p className={classes.quickReply} style={{ color, borderColor: color }}>
            Chilling!
          </p>
          <div className={classes.arrow}>
            <Next />
          </div>
        </div>
        <div className={classes.row}>
          <p className={classes.reply} style={{ backgroundColor: color }}>
            Superb!
          </p>
        </div>
      </div>
      <div className={classes.footer}>
        <p className={classes.inputPlaceholder}>{inputPlaceholder}</p>
        <SendIcon color={color} />
      </div>
    </div>
  )
}

export default WidgetChatScreen
