/* eslint-disable max-len */
export const circleChartContainerTitle = 'Conversations covered'
export const hourlyChartContainerTitle = 'Average conversations by time of day'

export const areaChartTooltipText =
  'This chart shows the number of Active users (unique users who were chatting with the bot) for the selected period.'
export const conversationsCoveredTooltipText =
  'This chart shows the ratio of conversations covered only by the bot and conversations in which a user asked for human support.'

export const hourlyChartTooltipText = `This chart shows the average amount of users per hour
        who were interacting with the bot for the selected period.`

export const channelsChartTooltipText = 'The percentage of users active on each channel over the selected period.'

export const noDataText = 'You have no conversations yet. They will appear here after your chatbot will get first users'
