export const styles = () => ({
  item: {
    display: 'flex',
    height: 80,
    padding: 10,
    cursor: 'pointer',
    borderRadius: 8,
    lineHeight: '14px',
    '&:hover': {
      backgroundColor: 'var(--color-hover-overlay-secondary)',
    },
  },
  text: {
    marginLeft: 8,
  },
  messageName: {
    fontSize: 14,
    color: 'rgba(43, 44, 54, 1)',
    marginBottom: 4,
  },
  messageDescription: {
    color: 'rgba(97, 101, 129, 1)',
    margin: 0,
    fontSize: 14,
    lineHeight: '17px',
  },
})
