import { BASE_URL } from '../../../configs'
import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { saveTags, addNewTag } from '../actions/tags'

export function loadTags(botId) {
  fetch(`${BASE_URL}/marketing/${botId}/tags`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      saveTags(json)
    })
    .catch(function(error) {
      alertError('Sorry but something is going wrong please ping support!')
    })
}

export function createTag(tag, botId, callback) {
  fetch(`${BASE_URL}/marketing/${botId}/tags`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(tag),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      addNewTag(json)
      if (callback) callback()
      alertSuccess('Tag is created successfully')
    })
    .catch(function(error) {
      alertError('Sorry but something is going wrong please ping support!')
    })
}
