export const styles = () => ({
  buttonsHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 0px 0px',
    flexWrap: 'wrap',
    width: 164,
  },
  text: {
    color: 'rgba(53, 64, 82, 1)',
    fontWeight: 700,
    textAlign: 'center',
  },
  subText: {
    color: 'var(--color-text-secondary)',
    textAlign: 'center',
  },
  alert: {
    color: '#FF6666',
    textAlign: 'center',
  },
  body: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderContainer: {
    height: 195,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
