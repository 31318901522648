export const styles = () => ({
  container: {
    borderRadius: 10,
    border: '1px solid var(--color-issabeline-grey)',
    width: '100%',
    marginBottom: 10,
    minWidth: 400,
    '&:hover': {
      '& i': {
        display: 'block',
      },
    },
  },
  header: {
    padding: '17px 0px 24px 24px',
    fontWeight: 700,
    color: 'rgba(58, 63, 98, 1)',
    borderBottom: '2px solid var(--color-issabeline-grey)',
  },
  body: {
    padding: 24,
  },
  label: {
    fontSize: 14,
    color: 'var(--color-oxford-blue)',
    marginBottom: 8,
    '& span': {
      color: 'red',
    },
  },
  section: {
    borderBottom: '1px solid var(--color-issabeline-grey)',
    marginBottom: 14,
  },
  inputWrap: {
    marginBottom: 14,
  },
  smallInputWrap: {
    width: '48%',
  },
  input: {
    width: '100%',
    fontSize: 14,
    outline: 'none',
    padding: 14,
    border: '1px solid var(--color-mischka-grey)',
    borderRadius: 10,
    height: 42,
    '&:hover': {
      border: '1px solid #9a9fa8',
    },
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
  },
  deleteTemplate: {
    right: '28px',
    top: '-16px',
    position: 'absolute',
    display: 'none',
    transition: 'all 0.5s ease',
  },
  deleteIcon: {
    background: 'var(--color-white)',
    border: '1px solid var(--color-issabeline-grey)',
    boxShadow: '0px 3px 6px var(--color-issabeline-grey)',
    width: 40,
    height: 40,
    position: 'absolute',
    right: -40,
    display: 'block',
  },
})
