import React, { useContext, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import AttributesModal from '../../../settings/components/general/AttributesModal/AttributesModal'
import { TrashIcon } from '../../../../uiKit/icons/TrashIcon'
import InputAutosuggest from '../../../../uiKit/InputAutosuggest/InputAutosuggest'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import AttributeSelect from '../../../../uiKit/AttributeSelect/AttributeSelect'
import { styles } from './styles'
import { PlusIcon } from '../../../../uiKit/icons/PlusIcon'
import { CHATBOT, DEFAULT } from '../../../../constants/attributeTypes'
import { MessagesContext } from '../MessagesWrap/MessagesWrap'
import { alertError } from '../../../../api'

const PostbackAttributes = props => {
  const { classes, atomAttributes, handleUpdateAttributes, callBack } = props
  const [open, setOpen] = useState(false)
  const [focus, setFocus] = useState(null)
  const { saveTime } = useContext(MessagesContext)
  const buttonName = atomAttributes?.length ? 'Add attribute' : 'Set attribute'
  const showPlusAttribute = !atomAttributes || atomAttributes?.length < 10

  const handleUpdateAttributeValue = (e, index) => {
    const attributesUpdate = deepCopyFunction(atomAttributes)?.map((attribute, i) => {
      if (i === index) {
        attribute.value = e.target.value || e.target.innerText
      }
      return attribute
    })

    handleUpdateAttributes(attributesUpdate)
  }

  const handleUpdateAttribute = (e, index) => {
    const isDuplicate = atomAttributes
      .filter((atom, i) => i !== index)
      .some(atom => atom.id === e.value)

    if (isDuplicate) {
      alertError('This attribute is already used.')
    } else {
      const atomAttributesCopy = deepCopyFunction(atomAttributes)?.map((attribute, i) => {
        if (i === index) {
          attribute.id = e.value
          attribute.name = e.label
        }

        return attribute
      })
      setFocus(false)
      handleUpdateAttributes(atomAttributesCopy)
    }
  }

  const handleAddAttribute = () => {
    const attributesUpdate = deepCopyFunction(atomAttributes) || []
    const newAttribute = { id: null, value: null, name: null }

    attributesUpdate.push(newAttribute)
    handleUpdateAttributes(attributesUpdate)
    if (callBack) callBack()
  }

  const handleDeleteAttribute = index => {
    const attributesUpdate = deepCopyFunction(atomAttributes)?.filter((attribute, i) => index !== i)

    handleUpdateAttributes(attributesUpdate)
    if (callBack) callBack()
  }

  return (
    <>
      <div className={classes.container}>
        {!!atomAttributes?.length && <div className={classes.title}>Set attribute</div>}
        <div>
          {atomAttributes?.map((attribute, index) => (
            <div className={classes.attributeWrap} key={index}>
              <AttributeSelect
                focus={focus === index || !attribute.id}
                value={attribute?.id}
                onChange={e => handleUpdateAttribute(e, index)}
                onBlur={() => setFocus(false)}
                onClick={() => setFocus(index)}
                menuPlacement={'top'}
                required={true}
                displayOptions={[DEFAULT, CHATBOT]}
                containerStyle={{ minWidth: 112, width: 112 }}
                isClearable={false}
                defaultAttributes={['language', 'close_conversation', 'conversation_outcome']}
                saveTime={saveTime}
                index={index}
              />
              <InputAutosuggest
                attributeId={attribute?.id}
                value={attribute?.value}
                placeholder={'Value'}
                style={{ width: 112 }}
                onChange={e => handleUpdateAttributeValue(e, index)}
                index={index}
              />
              <div className={classes.iconWrap} onClick={() => handleDeleteAttribute(index)}>
                <TrashIcon />
              </div>
            </div>
          ))}
        </div>
      </div>
      {showPlusAttribute && (
        <div className={classes.addButton} onClick={handleAddAttribute} data-autotest={'add-attribute-button'}>
          <PlusIcon /> {buttonName}
        </div>
      )}
      <AttributesModal open={open} onClose={() => setOpen(false)} />
    </>
  )
}

PostbackAttributes.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBotId: state.activeBot?.id,
})

export default withRouter(withStyles(styles)(connect(mapStateToProps)(PostbackAttributes)))
