import { useState } from 'react'

import { stableSort } from '../../helpers/stableSort'
import { getSorting } from '../../helpers/getSorting'

import { TABLE_PAGINATION_ROWS_PER_PAGE_OPTIONS } from '../../constants/styles'

const INITIAL_PAGE = 1
const INITIAL_ROWS_PER_PAGE = +TABLE_PAGINATION_ROWS_PER_PAGE_OPTIONS[0].value

export const usePaginationHandler = props => {
  const [page, setPage] = useState(INITIAL_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(INITIAL_ROWS_PER_PAGE)

  const sortedRows = stableSort(props.data, getSorting(props.order, props.orderBy))

  const from = (page - 1) * rowsPerPage
  const to = (page - 1) * rowsPerPage + rowsPerPage
  const selectedRows = sortedRows.slice(from, to)
  const rows = rowsPerPage > 0 ? selectedRows : sortedRows
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedRows.length - from)
  const allPagesCount = Math.ceil(props.data?.length / rowsPerPage)
  const allRowsCount = props.data?.length
  const isLastPage = rows.length !== rowsPerPage
  const isFirstPage = page === 1

  // for input
  const handleChangePage = e => {
    const newPage = e.target.value

    if (newPage < 1) {
      setPage(1)
      return
    }
    if (newPage > allPagesCount) {
      setPage(allPagesCount)
      return
    }

    setPage(newPage)
  }

  const handlePrevPage = () => setPage(page - 1)
  const handleNextPage = () => setPage(page + 1)

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.value)
    setPage(1)
  }

  return {
    page,
    rows,
    from,
    emptyRows,
    allRowsCount,
    rowsPerPage,
    isLastPage,
    isFirstPage,
    allPagesCount,
    handlePrevPage,
    handleNextPage,
    handleChangePage,
    handleChangeRowsPerPage,
  }
}
