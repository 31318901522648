import {
  CHATBOT,
  FACEBOOK,
  TWILIO,
  WHATSAPP,
  WIDGET,
} from '../constants/attributeTypes'

export const getAttributeColor = type => {
  switch (type) {
  case CHATBOT:
    return '#5A98F7'
  case WIDGET:
    return 'var(--color-button-secondary)'
  case FACEBOOK:
    return '#1877F2'
  case WHATSAPP:
    return '#33D26B'
  case TWILIO:
    return '#F22F46'
  default:
    return '#5A98F7'
  }
}
