export const styles = () => ({
  container: {
    borderBottom: '1px solid var(--color-issabeline-grey)',
    paddingBottom: 32,
    marginBottom: 32,
  },
  btnDelete: {
    marginTop: 16,
    cursor: 'pointer',
    border: '1px solid rgba(97, 101, 129, 0.5)',
    boxSizing: 'content-box',
    borderRadius: 10,
    color: 'rgba(97, 101, 129, 0.5)',
    padding: '8px 28px',
    width: 'max-content',
    fontSize: 14,
    lineHeight: 'normal',
    fontFamily: 'Lato',
  }
})
