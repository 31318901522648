import { RefObject } from 'react'

export const addAttributeToAfterText = (
  newAttr: HTMLElement,
  anchorNode: HTMLElement,
  caretPos: any,
  inputRef: RefObject<HTMLElement>,
) => {
  const text1 = anchorNode.nodeValue?.substr(0, caretPos.startOffset) || ''
  const text2 = anchorNode.nodeValue?.substr(caretPos.startOffset) || ''
  const text1Node = document.createTextNode(text1)
  const text2Node = document.createTextNode(text2)
  inputRef?.current?.replaceChild(text1Node, anchorNode)
  inputRef?.current?.insertBefore(newAttr, text1Node.nextSibling)
  inputRef?.current?.insertBefore(text2Node, newAttr.nextSibling)
}
