import styled from 'styled-components'

const Body = styled.div`
  padding: 24px 40px 50px;
  overflow: scroll;
  height: calc(90vh - 205px);
`

const Paragraph = styled.div`
  color: var(--color-text-primary);
  margin: 24px 0 8px;
  font-size: 16px;
  font-weight: 600;
`

const LoaderScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 345px;
  width: 100%;
`

const Textarea = styled.textarea<{ disabled: boolean }>`
  position: relative;
  height: 100px;
  font-size: 14px;
  padding: 14px 24px 10px 24px;
  word-break: break-word;
  color: ${props => (props.disabled ? 'var(--color-ghost-grey)' : 'var(--color-text-secondary)')};
  border: 1px solid var(--color-ghost-grey);
`

export { Body, Paragraph, LoaderScreen, Textarea }
