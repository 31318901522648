import React, { useState } from 'react'
import { CloseIcon } from 'uiKit/icons/Icons.js'
import { DELETE_ATOM_MODAL, DELETE_ATOM_ALERT_MODAL } from '../../constants/deleteAtomModalTypes'
import { deleteAtom } from '../../api/atom'

import DeleteAtomModal from '../DeleteAtomModal'
import DeleteAtomAlertModal from '../DeleteAtomAlertModal'

import * as S from './DeleteAtom.style'

interface Props {
  index?: number
  atom: any
  activeBotId: number
  selectWelcomeAtom: () => void
}

export const DeleteAtom: React.FC<Props> = ({ atom, activeBotId, selectWelcomeAtom, index }) => {
  const [open, setOpen] = useState('')
  const [details, setDetails] = useState(null)

  const handleDeleteAtom = () => {
    deleteAtom(atom.id, activeBotId)
      .then(data => {
        if (data) {
          setOpen(DELETE_ATOM_ALERT_MODAL)
          setDetails(data)
        } else {
          setOpen('')
          selectWelcomeAtom()
        }
      })
      .catch(error => console.log(error))
  }

  const renderModal = () => {
    if (open === DELETE_ATOM_MODAL) {
      return <DeleteAtomModal atom={atom} handleClose={() => setOpen('')} handleDelete={handleDeleteAtom} />
    } else if (open === DELETE_ATOM_ALERT_MODAL) {
      return <DeleteAtomAlertModal details={details} atom={atom} handleClose={() => setOpen('')} />
    } else {
      return null
    }
  }

  return (
    <S.DeleteAtom>
      <div onClick={() => setOpen(DELETE_ATOM_MODAL)} data-autotest={`button-delete-atom-${index}`}>
        <CloseIcon width="10px" height="10px" color={'var(--color-spun-pearl-grey)'} />
      </div>
      {renderModal()}
    </S.DeleteAtom>
  )
}
