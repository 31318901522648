import React from 'react'

import { DisconnectPageModal } from '../DisconnectPageModal'
import { FacebookActivePagesTable } from '../FacebookActivePagesTable'
import { DisconnectPageModalProvider } from '../DisconnectPageModal/context'

interface Props {
  botId: number
  fbToken: string
  getConnectButton: () => void
}

export const FacebookActivePages: React.FC<Props> = props => (
  <DisconnectPageModalProvider>
    <FacebookActivePagesTable botId={props.botId}/>
    <DisconnectPageModal botId={props.botId} fbToken={props.fbToken} />
  </DisconnectPageModalProvider>
)
