import { getAttributeColor } from 'helpers/getAttributeColor'
import {AttributeItemType} from 'models/AttributeTypes'

export const generateAttribute = (currentAttribute: AttributeItemType, attribute: string) => {
  const newAttr = document.createElement('attr')
  newAttr.style.display = 'inline-block'
  newAttr.style.minWidth = '15px'
  newAttr.style.minHeight = '15px'
  newAttr.style.color = 'var(--color-white)'
  newAttr.style.backgroundColor = getAttributeColor(currentAttribute?.type)
  newAttr.setAttribute('contenteditable', 'false')
  newAttr.style.borderRadius = '50px'
  newAttr.innerHTML = attribute

  return newAttr
}
