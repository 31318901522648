/**
 * Created by Admin on 26.02.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const customSelectorStyles = {
  control: (provided, state) => ({
    ...provided,
    outline: 'none',
    boxShadow: 'none',
    borderColor: '#cccccc',
    '&:hover': {
      borderColor: '#cccccc',
    },
    '&:focus': {
      borderColor: '#cccccc',
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgba(53, 64, 82, 0.5)',
    fontSize: 14,
    width: 'max-content',
  }),
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: 14,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
      color: 'var(--color-text-primary)',
      padding: '8px 4px',
      '&:hover': {
        fontSize: 14,
        backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
        color: 'var(--color-text-primary)',
      },
    }
  },
}

class HoursSpecification extends React.Component {
  render() {
    const { classes, days, type, label, timeList } = this.props
    return (
      <div className={classes.dateContainer}>
        <FormControlLabel
          className="checkbox"
          control={
            <Checkbox
              style ={{
                color: 'var(--color-button-secondary)',
              }}
              onChange={() => this.props.handleSet(type)}
              value={this.props.value.toString()}
              checked={this.props.value}
              color="primary"
            />
          }
          label={label.charAt(0).toUpperCase() + label.slice(1)}
        />
        <span style={{ marginTop: -10 }}>from</span>
        <div style={{ width: 135, margin: '-10px 16px 0 16px' }}>
          <Select
            styles={customSelectorStyles}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            options={timeList}
            simpleValue
            value={timeList.find(
              time =>
                time.value ===
                (days && days.startTime != null ? days.startTime : '09:00'),
            )}
            name="selected-state"
            onChange={event =>
              this.props.onChange(event.value, 'startTime', label)
            }
            searchable
          />
        </div>
        <span style={{ marginTop: -10 }}>to</span>
        <div style={{ width: 135, margin: '-10px 16px 0 16px' }}>
          <Select
            styles={customSelectorStyles}
            options={timeList}
            simpleValue
            value={timeList.find(
              time =>
                time.value ===
                (days && days.endTime != null ? days.endTime : '18:00'),
            )}
            onChange={event =>
              this.props.onChange(event.value, 'endTime', label)
            }
            searchable
          />
        </div>
      </div>
    )
  }
}

HoursSpecification.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withRouter(connect(mapStateToProps)(HoursSpecification))
