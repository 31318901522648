import React from 'react'
import { CheckIcon, ReactionIcon, TrashIcon } from 'uiKit/icons/Icons'
import {
  ItemChipHelperContainer,
  ChipHelperButtonContainer,
  ChipHelperButtonTooltip,
  CheckIconContainer
} from './ItemChipHelper.styles'

interface Props {
  onDelete: () => void
  addReaction: () => void
  enableReaction: boolean
}

const ItemChipHelper: React.FC<Props> = ({ onDelete, addReaction, enableReaction }) => {

  return (
    <ItemChipHelperContainer>
      <ChipHelperButtonContainer onClick={addReaction} enableReaction={enableReaction}>
        <ChipHelperButtonTooltip>Enable reactions</ChipHelperButtonTooltip>
        {enableReaction &&
          <CheckIconContainer>
            <CheckIcon />
          </CheckIconContainer>}
        <ReactionIcon width="20px" height="20px" color={'var(--color-spun-pearl-grey)'} />
      </ChipHelperButtonContainer>

      <ChipHelperButtonContainer aria-label="Delete" onClick={onDelete}>
        <TrashIcon width="20px" height="20px" color={'var(--color-spun-pearl-grey)'} />
      </ChipHelperButtonContainer>
    </ItemChipHelperContainer>
  )
}

export default ItemChipHelper
