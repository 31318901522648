import React, { useState, useRef } from 'react'
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from '@material-ui/core'

import { MoreHorizontalIcon, DeleteIcon } from 'uiKit/icons/Icons'
import { EditIcon } from 'uiKit/icons/EditIcon'

import { useDisconnectPageModalContext } from '../DisconnectPageModal/context'

interface Props {
  classes: any
  page: any
  openEditModalHandle: () => void
}

export const PageMenu: React.FC<Props> = props => {
  const { classes } = props
  const [open, setOpen] = useState(false)
  const { handleOpenDisconnectPageModal } = useDisconnectPageModalContext()
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDisconnectPage = () => {
    handleOpenDisconnectPageModal(props.page)
    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root} ref={anchorRef}>
      <ClickAwayListener onClickAway={handleClose}>
        <Button
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.button}>
          <span style={{ transform: open && 'rotate(180deg)' }}>
            <MoreHorizontalIcon />
          </span>
        </Button>
        <Popper
          transition
          disablePortal
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          className={classes.popper}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
              <Paper className={classes.paper}>
                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown} className={classes.list}>
                  <MenuItem onClick={props.openEditModalHandle} className={classes.listItem}>
                    <span className={classes.editBtn}>
                      <EditIcon /> Edit
                    </span>
                  </MenuItem>
                  <MenuItem className={props.classes.listItem} onClick={handleDisconnectPage}>
                    <span className={classes.deleteBtn}>
                      <DeleteIcon /> Disconnect
                    </span>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </div>
  )
}
