export const styles = () => ({
  container: {
    borderBottom: '1px solid var(--color-issabeline-grey)',
    paddingBottom: 32,
    marginBottom: 32,
  },
  text: {
    color: 'rgba(53, 64, 82, 1)',
    fontSize: 18,
    '& > span': {
      cursor: 'pointer',
      margin: '0px 10px',
    },
  },
  userImage: {
    display: 'block',
    width: 32,
    objectFit: 'cover',
    objectPosition: 'top',
    borderRadius: '50%',
    overflow: 'hidden',
    height: 32,
    position: 'relative',
    marginRight: 8,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  userNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
})
