import React, { FC, useMemo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ColumnChart } from 'react-chartkick'
import 'chartjs-plugin-labels'

import Tooltip from '../../../../uiKit/Tooltip'
import NoChartData from '../../../../uiKit/NoChartData'

import classes from './styles.module.scss'
import { FunnelStepType, FunnelType } from '../../../../models/FunnelTypes'
import { getColorButtonPrimary, rgbToHex } from '../../../../globalStylesHelper.js'

interface Props extends RouteComponentProps {
  funnel: FunnelType
  atoms: any[]
  onSelect: (a: number) => void
}

const CompletionRateChart: FC<Props> = ({ funnel, atoms, onSelect }) => {
  const data = useMemo(getData, [funnel])
  const libraryOptions = useMemo(getLibraryOptions, [funnel, atoms])
  const isData = useMemo(
    () => data?.reduce((sum, [, conversationPercentage]) => sum + conversationPercentage, 0),
    [data],
  )

  function getData() {
    return funnel?.steps?.map(({ stepNumber, conversationPercentage }) => [stepNumber, conversationPercentage])
  }

  function getLibraryOptions() {
    return {
      layout: {
        padding: {
          top: 20,
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem: { label: number }) => {
            const userCount = funnel?.steps?.find(
              ({ stepNumber }: FunnelStepType) => stepNumber == tooltipItem.label,
            )?.conversationCount
            return `${userCount} conversations`
          },
          title: () => '',
        },
      },
      scales: {
        xAxes: [
          {
            barPercentage: funnel?.steps?.length * 0.1,
            display: false,
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (value: string) => `${value} %`,
            },
          },
        ],
      },
      plugins: {
        labels: {
          render: ({ value }: any) => `${value} %`,
        },
      },
    }
  }

  const getAtomNames = (atomIds: number[]) => {
    return atomIds?.map(atomId => atoms?.find(({ id }) => id === atomId)?.name || '')?.join(' | ')
  }

  const colorButtonPrimary = getColorButtonPrimary()

  return (
    <div className={!isData ? classes.container : ''}>
      {isData ? (
        <>
          <ColumnChart
            data={data}
            colors={[rgbToHex(colorButtonPrimary)]}
            library={libraryOptions}
            height="calc(100vh - 490px)"
          />

          <div className={classes.row}>
            {funnel?.steps?.map(({ atomIds, stepNumber }) => (
              <div className={classes.atomName} data-for={stepNumber.toString()} data-tip key={stepNumber}>
                {atoms.find(({ id }) => id === atomIds[0])?.name}
                <Tooltip id={stepNumber.toString()}>
                    Step {stepNumber}: {getAtomNames(atomIds)}
                </Tooltip>
              </div>
            ))}
          </div>

          <div className={classes.row}>
            {funnel?.steps?.map(({ stepNumber, conversationCount }) => (
              <div key={stepNumber} className={classes.link} onClick={() => onSelect(stepNumber)}>
                {conversationCount ? 'View transcripts' : ''}
              </div>
            ))}
          </div>
        </>
      ) : (
        <NoChartData text={'No data during selected time range'} />
      )}
    </div>
  )
}

const mapStateToProps = (state: { funnel: FunnelType; atoms: any[] }) => ({
  funnel: state.funnel,
  atoms: state.atoms,
})

export default connect(mapStateToProps)(withRouter(CompletionRateChart))
