import styled from 'styled-components'
import SubmitButton from 'uiKit/buttons/SubmitButton'

const DialogflowIntegration = styled.div`
  label {
    margin-bottom: 0;
  }
`

const Label = styled.p`
  font-size: 12px;
  color: var(--color-spun-pearl-grey);
  margin: 4px 0 12px;
`

const Error = styled.p`
  color: var(--color-text-error);
  font-size: 12px;
  margin: 4px 0 12px;
`

const SubmitButtonStyled = styled(SubmitButton)<{ isMarginBottom?: boolean }>`
  width: fit-content;
  margin-bottom: ${({ isMarginBottom }) => (isMarginBottom ? 32 : 0)}px;
`

export { DialogflowIntegration, Label, Error, SubmitButtonStyled }
