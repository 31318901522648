import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import xss from 'xss'

import { deepCopyFunction } from 'helpers/deepCopyFunction'
import AttributeSelectItem from 'uiKit/AttributeSelectItem/AttributeSelectItem'

import { styles } from './styles'

const AttributesModalList = props => {
  const { classes, attributes, searchValue, setAttributes, error, setShowError, isRtl, allAttributes } = props

  const [focus, setFocus] = useState(null)

  const handleFocus = focus => {
    if (!error) setFocus(focus)
  }

  const handleUpdateAttributeValue = (e, attributeId) => {
    const attributesUpdate = deepCopyFunction(attributes).map(attribute => {
      if (attribute.id === attributeId) {
        attribute.defaultValue = xss(e.target.value)
      }
      return attribute
    })

    setAttributes(attributesUpdate)
  }

  const handleUpdateAttributeName = (e, attributeId) => {
    const attributesUpdate = deepCopyFunction(attributes).map(attribute => {
      if (attribute.id === attributeId) {
        attribute.name = e.target.value
        attribute.isValid = true
      }
      return attribute
    })

    if (allAttributes.some(({ name }) => name === e.target.value)) {
      setShowError('This attribute name is already used')
    } else if (e.target.value && !/^[a-z_]+$/.test(e.target.value)) {
      setShowError('Attribute name can contain lowercase letters and underscore')
    } else {
      setShowError(false)
    }

    setAttributes(attributesUpdate)
    setFocus(attributeId)
  }

  const handleDeleteAttribute = attributeId => {
    const attributesUpdate = deepCopyFunction(attributes).filter(attribute => attribute.id !== attributeId)

    setAttributes(attributesUpdate)
  }

  const handleEnterAttributeName = e => {
    if (e.key === 'Enter') {
      handleFocus(false)
    }
  }

  return (
    <>
      <div className={classes.labels}>
        <p>Name</p>
        <p>Default value</p>
      </div>
      {attributes
        .filter(attribute => attribute.name.includes(searchValue))
        .map((attribute, index) => (
          <AttributeSelectItem
            key={attribute.id}
            attribute={attribute}
            focus={focus}
            error={error}
            isRtl={isRtl}
            index={index}
            handleUpdateAttributeName={handleUpdateAttributeName}
            handleEnterAttributeName={handleEnterAttributeName}
            handleUpdateAttributeValue={handleUpdateAttributeValue}
            handleFocus={handleFocus}
            handleDeleteAttribute={handleDeleteAttribute}
          />
        ))}
    </>
  )
}

AttributesModalList.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(AttributesModalList)
