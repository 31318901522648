import { createContext, useContext } from 'react'

export const PaginationTableContext = createContext({})

export function usePaginationTableContext() {
  const context = useContext(PaginationTableContext)

  if (!context) {
    throw new Error('usePaginationTableContext must be inside PaginationTableProvider')
  }

  return context
}
