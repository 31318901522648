import React from 'react'
import * as S from './EmptyScreen.style'
import { Clip } from 'uiKit/icons/Clip'
import SubmitButton from 'uiKit/buttons/SubmitButton'

export const EmptyScreen = ({ settingsPages }) => {
  return (
    <S.EmptyScreen>
      <Clip />
      <S.Text>
        {settingsPages?.facebookPages?.length
          ? 'Your chatbot is not connected to any Facebook Page'
          : 'Looks like you have no Facebook pages yet. \n Create the first one to be able to connect your bot.'}
      </S.Text>
      <SubmitButton
        size="sm"
        title={'Create new Facebook page'}
        onClick={() => window.open('https://www.facebook.com/pages/creation', '_blank')}
        type={'primary_with_border'}
      />
    </S.EmptyScreen>
  )
}
