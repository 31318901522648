import React, { useEffect, useMemo, useState } from 'react'
import { usePrevious } from 'react-use'

import ItemsListTab from '../ItemsListTab/ItemsListTab'
import BodyTab from '../BodyTab/BodyTab'
import ResponseTab from '../ResponseTab/ResponseTab'
import TestRequestScreen from '../TestRequestScreen/TestRequestScreen'

import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'

import classes from './styles.module.scss'
import {ApiCallMessageType, OptionMessageItemType} from 'models/MessageTypes'

interface Props {
  scrollBlock: any
  message: ApiCallMessageType
  updateMessage(message: ApiCallMessageType): void
}
type TabType = 'Headers' | 'Body' | 'Response' | 'Response mapping'

const tabs: TabType[] = ['Headers', 'Body', 'Response', 'Response mapping']

const ApiCallTabs: React.FC<Props> = ({
  scrollBlock,
  message,
  updateMessage,
}) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [response, setResponse] = useState(null)
  const prevMessage = usePrevious(message)
  const messageCopy = useMemo(() => deepCopyFunction(message), [message])

  useEffect(() => {
    if (JSON.stringify(prevMessage) !== JSON.stringify(message)) {
      setResponse(null)
    }
  }, [message])

  const handleUpdateHeaders = (headers: OptionMessageItemType[]) => {
    messageCopy.apiCall.headers = headers
    updateMessage(messageCopy)
  }

  const handleUpdateAttributes = (attributes: OptionMessageItemType[]) => {
    messageCopy.apiCall.attributes = attributes
    updateMessage(messageCopy)
  }

  const handleUpdateBody = (body: string) => {
    messageCopy.apiCall.body = body
    updateMessage(messageCopy)
  }

  const handleCheckbox = () => {
    messageCopy.apiCall.addUserData = !messageCopy.apiCall.addUserData
    updateMessage(messageCopy)
  }

  const handleSelectTab = (tab: TabType) => {
    setSelectedTab(tab)
  }

  const handleClearResponse = () => {
    setResponse(null)
    setSelectedTab(tabs[2])
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={selectedTab === tab ? classes.activeTab : classes.tab}
              onClick={() => handleSelectTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div className={classes.button} onClick={handleClearResponse}>
          Test the request
        </div>
      </div>

      {selectedTab === tabs[0] && (
        <ItemsListTab
          onChange={handleUpdateHeaders}
          items={message.apiCall.headers}
          isHeaders={true}
        />
      )}

      {selectedTab === tabs[1] && (
        <BodyTab
          body={message.apiCall.body}
          addUserData={message.apiCall.addUserData}
          scrollBlock={scrollBlock}
          onChange={handleUpdateBody}
          onCheck={handleCheckbox}
        />
      )}

      {selectedTab === tabs[2] &&
        (response ? (
          <ResponseTab data={response} />
        ) : (
          <TestRequestScreen
            apiCall={message.apiCall}
            setResponse={setResponse}
          />
        ))}

      {selectedTab === tabs[3] && (
        <ItemsListTab
          onChange={handleUpdateAttributes}
          items={message.apiCall.attributes}
          isHeaders={false}
        />
      )}
    </div>
  )
}

export default ApiCallTabs
