export const styles = theme => ({
  button: {
    cursor: 'pointer',
    fontSize: 14,
    padding: '8px 16px',
    border: '1px solid var(--color-button-secondary)',
    borderRadius: 4,
    color: 'var(--color-button-secondary)',
    width: 'fit-content',
    fontWeight: 500,
    backgroundColor: 'var(--color-white)',
  },
})
