export const styles = theme => ({
  container: {
    display: 'flex',
    width: '100%',
    height: 72,
    borderBottom: '1px solid rgba(53, 64, 82, 0.1)',
  },
  tab: {
    width: '50%',
    fontSize: 14,
    color: theme.tabs.support.tabText,
    background: theme.tabs.support.tabBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  activeTab: {
    color: 'var(--color-text-on-secondary)',
    background: 'var(--color-button-secondary)',
  },
})
