import styled from 'styled-components'

const Container = styled.div`
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: 4px 4px 29px rgba(19, 69, 186, 0.0855129);
`

const ChartWrap = styled.div`
  display: flex;
  height: 496px;
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 496px;
  justify-content: center;
  width: 100%;
`

export { Container, ChartWrap, LoaderContainer }
