import React, { ReactEventHandler, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import GeneralTab from '../GeneralTab'
import CustomizationTab from '../CustomizationTab'
import ModalTemplate from '../../../../uiKit/ModalTemplate'
import { getAttributes } from '../../../settings/api/attributes'
import { getFlows } from '../../../flowBuilder/api/flow'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { updateWidget } from '../../api/widget'
import { isStringEmpty } from '../../../../helpers/isStringEmpty'
import { expressionValidation } from '../../../../helpers/expressionValidation'
import { validateStringLength } from '../../../../helpers/validateStringLength'

import classes from './styles.module.scss'
import { GreetingType, WidgetSettingsType } from '../../../../models/WidgetSettingsTypes'
import { OptionItemType } from '../../../../models/MessageTypes'

type TabType = 'General' | 'Customization'

const tabs: TabType[] = ['General', 'Customization']

const greetingData: GreetingType = {
  name: '',
  greetingText: '',
  delaySeconds: 5,
  startAtomId: null,
  enabled: true,
  condition: {
    conjunction: 'AND',
    expressions: [
      {
        operator: 'IS',
        value: '',
        attribute: null,
      },
    ],
  },
}

interface Props {
  match: any
  open: boolean
  selectedLanguage: OptionItemType
  widgetSettings: WidgetSettingsType
  onClose: ReactEventHandler
}

const AddGreetingModal: React.FC<Props> = ({ open, selectedLanguage, match, widgetSettings, onClose }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [greeting, setGreeting] = useState(greetingData)
  const [saveTime, setSaveTime] = useState(new Date())

  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])

  useEffect(() => {
    getAttributes(match.params.botId, selectedLanguage?.value)
    getFlows(match.params.botId)
  }, [])

  useEffect(() => {
    setGreeting(greetingData)
    setSelectedTab(tabs[0])
  }, [open])

  const handleUpdateGreeting = (newGreeting: GreetingType) => {
    setGreeting(newGreeting)
  }

  const handleSave = (event: any) => {
    const error = validateGreeting()
    setSaveTime(new Date())

    if (!error) {
      greeting.delaySeconds = Number(greeting.delaySeconds)
      widgetCopy.languageConfigs[selectedLanguage.value].greetings.push(greeting)
      updateWidget(match.params.botId, widgetCopy)
      onClose(event)
    }
  }

  const validateGreeting = () => {
    const { name, greetingText, startAtomId, delaySeconds, condition } = greeting
    const errorAtGeneral = !validateStringLength(name, 20) || condition.expressions.some(expressionValidation)
    const errorAtCustomization = isStringEmpty(greetingText) || !startAtomId || !delaySeconds

    if (errorAtGeneral && !errorAtCustomization) {
      setSelectedTab(tabs[0])
    } else if (!errorAtGeneral && errorAtCustomization) {
      setSelectedTab(tabs[1])
    }

    return errorAtGeneral || errorAtCustomization
  }

  return (
    <ModalTemplate open={open} title="Add new greeting" onClose={onClose} onSave={handleSave}>
      <div>
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={selectedTab === tab ? classes.activeTab : classes.tab}
              onClick={() => setSelectedTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div className={classes.body} id="scrollable">
          {selectedTab === tabs[0] && (
            <GeneralTab greeting={greeting} onChange={handleUpdateGreeting} saveTime={saveTime} />
          )}
          {selectedTab === tabs[1] && (
            <CustomizationTab greeting={greeting} onChange={handleUpdateGreeting} saveTime={saveTime} />
          )}
        </div>
      </div>
    </ModalTemplate>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType }) => ({
  widgetSettings: state.widgetSettings,
})

export default withRouter(connect(mapStateToProps)(AddGreetingModal))
