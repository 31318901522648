import React from 'react'

import { WidgetIntegrationSubTabMap } from 'tabs/widget/constants'

import * as S from './RadioButtonGroup.style'

const options = [
  {
    label: 'Widget',
    value: WidgetIntegrationSubTabMap.WIDGET,
  },
  {
    label: 'Embedded AI chat',
    value: WidgetIntegrationSubTabMap.AI_CHAT,
  }
]

interface Props {
  value: string
  onChange: (a: string) => void
}

export const RadioButtonGroup: React.FC<Props> = ({ value, onChange }) => {
  return (
    <S.RadioButtonGroup>
      {options.map(option => (
        <S.Option key={option.value} isActive={option.value === value} onClick={() => onChange(option.value)}>
          {
            option.label
          }
        </S.Option>
      ))}
    </S.RadioButtonGroup>
  )
}
