import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Input from '../../../../../uiKit/inputs/Input'
import {
  getTwilioCredentials,
  submitTwilioCredentials,
} from '../../../api/twilioCredetials'
import { alertError } from '../../../../../api'
import { phoneNumberValidation } from '../../../../../helpers/phoneNumberValidation'

const styles = theme => ({
  description: {
    fontSize: '14px',
    color: 'var(--color-text-secondary)',
    fontFamily: 'Lato, sans-serif',
    margin: '0 0 8px 0',
  },
  connectionBtn: {
    color: 'var(--color-button-primary)',
    border: '1px solid var(--color-button-primary)',
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '8px 16px',
    cursor: 'pointer',
    '&:disabled': {
      backgroundColor: 'var(--color-athens-grey)',
      color: 'var(--color-mischka-grey)',
      borderColor: 'var(--color-mischka-grey)',
    }
  },
  statusContent: {
    fontSize: '17px',
    lineHeight: '10px',
  },
  statusBlock: {
    marginBottom: '35px',
    width: '400px',
  },
  popupBtnBlock: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '40px',
  },
  instructionBtn: {
    color: 'var(--color-button-primary)',
    cursor: 'pointer',
    margin: '0 0 0 8px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

const showConnectionText = connection => {
  return connection ? 'Connected' : 'Not connected'
}
const showConnectionStyle = connection => {
  return { color: connection ? 'var(--color-malachite-green)' : 'var(--color-spun-pearl-grey)' }
}
const inputStyle = {
  marginBottom: '24px',
  width: '400px',
}

class TwilioIntegration extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      twilioCredentials: {
        accountSID: '',
        authToken: '',
        phone: '',
        connected: false,
      },
      error: {
        accountSIDError: false,
        authTokenError: false,
        phoneError: false,
      },
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.twilioCredentials !== prevState.twilioCredentials) {
      return {
        twilioCredentials: nextProps.twilioCredentials,
      }
    }

    return null
  }

  componentDidMount() {
    const { activeBot } = this.props
    getTwilioCredentials(activeBot.id)
  }

  submitCredentials = event => {
    event.preventDefault()
    const { activeBot } = this.props
    const { twilioCredentials } = this.state

    const error = {
      accountSIDError: twilioCredentials.accountSID === '',
      authTokenError: twilioCredentials.authToken === '',
      phoneError:
        twilioCredentials.phone === '' ||
        !phoneNumberValidation(twilioCredentials.phone),
    }

    if (error.accountSIDError) {
      alertError('Please enter account SID')
    }
    if (error.authTokenError) {
      alertError('Please enter account access token')
    }
    if (error.phoneError && !phoneNumberValidation(twilioCredentials.phone)) {
      alertError('Please enter valid phone number')
    }

    if (!error.accountSIDError && !error.authTokenError && !error.phoneError) {
      submitTwilioCredentials(activeBot.id, twilioCredentials)
    }

    this.setState({ error })
  }

  handleChange = (event, key) => {
    const twilioCredentials = Object.assign(this.state.twilioCredentials)

    twilioCredentials[key] = event.target.value

    this.setState({ twilioCredentials })
  }

  render() {
    const { classes } = this.props
    const { twilioCredentials, error } = this.state
    const disableButton = !twilioCredentials?.accountSID?.length
      || !twilioCredentials?.phone?.length
      || !twilioCredentials?.authToken?.length

    return (
      <div>
        <div className={classes.statusBlock}>
          <span>
            <p className={classes.description}>Status:</p>
            <p
              className={classes.statusContent}
              style={showConnectionStyle(twilioCredentials.connected)}>
              {showConnectionText(twilioCredentials.connected)}
            </p>
          </span>
        </div>
        <form onSubmit={this.submitCredentials}>
          <Input
            title={'Account SID'}
            error={error.accountSIDError}
            wrapperStyle={inputStyle}
            type={'text'}
            placeholder={'Enter account SID'}
            value={twilioCredentials.accountSID || ''}
            onChange={value => this.handleChange(value, 'accountSID')}
          />
          <Input
            title={'Account access token'}
            wrapperStyle={inputStyle}
            error={error.authTokenError}
            placeholder={'Enter account access token'}
            value={twilioCredentials.authToken || ''}
            onChange={value => this.handleChange(value, 'authToken')}
          />
          <Input
            title={'Bot’s phone number'}
            error={error.phoneError}
            wrapperStyle={inputStyle}
            type={'text'}
            placeholder={'Enter bot’s phone number'}
            value={twilioCredentials.phone || ''}
            onChange={e => this.handleChange(e, 'phone')}
          />
          <div className={classes.popupBtnBlock}>
            <button className={classes.connectionBtn} type="submit" disabled={disableButton}>
              Connect
            </button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  twilioCredentials: state.twilioCredentials,
  activeBot: state.activeBot,
})

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps)(TwilioIntegration),
)
