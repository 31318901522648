import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Heading from '../../../../../uiKit/texts/Heading'
import Paragraph from '../../../../../uiKit/texts/Paragraph'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import AttributesModal from '../AttributesModal/AttributesModal'

import { styles } from './styles'

const AttributeBlock = ({ classes, activeBot }) => {
  const [openAttributeModal, setOpenAttributeModal] = useState(false)

  const handleOpenAttributeModal = openAttributeModal => {
    setOpenAttributeModal(openAttributeModal)
  }

  return (
    <>
      <div className={classes.container}>
        <Heading>Chatbot Attributes</Heading>
        <Paragraph>
          Set default values for the attributes. Use them to quickly insert your
          brand's info into a chatbot.
        </Paragraph>
        <SubmitButton
          title={'Manage attributes'}
          type={'primary_with_border'}
          styles={{ borderRadius: 4, marginTop: 16 }}
          onClick={() => handleOpenAttributeModal(true)}
        />
      </div>

      <AttributesModal
        open={openAttributeModal}
        onClose={() => handleOpenAttributeModal(false)}
        botName={activeBot.name}
      />
    </>
  )
}

AttributeBlock.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
})

export default withStyles(styles)(connect(mapStateToProps)(AttributeBlock))
