import React from 'react'

import DeleteAtom from '../DeleteAtom'
import { HOME_PATH } from 'configs'
import * as S from './AtomItem.style'

interface Props {
  atom: any
  activeBotId: number
  selectWelcomeAtom: () => void
  index?: number
}

export const AtomItem: React.FC<Props> = ({ atom, activeBotId, selectWelcomeAtom, index }) => {
  return (
    <S.AtomItem to={`${HOME_PATH}/bot/${activeBotId}/flows/${atom.id}`} data-testid={`atom-button-${atom.id}`}>
      {atom.name}
      {!atom.isHardCoded && (
        <DeleteAtom atom={atom} activeBotId={activeBotId} selectWelcomeAtom={selectWelcomeAtom} index={index} />
      )}
    </S.AtomItem>
  )
}
