import styled from 'styled-components'

const EmptyScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  height: 200px;
  gap: 20px;
`

const Text = styled.div`
  color: var(--color-text-secondary);
  font-size: 14px;
  white-space: pre-wrap;
  text-align: center;
`

export { EmptyScreen, Text }
