/**
 * Created by Admin on 05.02.2018.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import { deleteFlow } from '../api/flow'
import { TrashIcon } from '../../../uiKit/icons/Icons.js'
import Modal from '../../../uiKit/Modal'
import SubmitButton from '../../../uiKit/buttons/SubmitButton'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { theme } from 'constants/theme'

const styles = theme => ({
  buttonsHolder: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '24px 70px',
  },
})

class DeleteFlow extends React.Component {
  state = {
    open: false,
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  deleteFlow() {
    const { flow } = this.props

    flow &&
      flow.atoms &&
      flow.atoms.forEach(atom => {
        const atomId = atom.id
        if (atomId == this.props.atomId) {
          this.props.selectAtom(this.props.flows[0].atoms[0].id)
        }
      })
    deleteFlow(
      this.props.flow.id,
      this.props.match.params.botId,
      this.handleClose,
    )
  }

  render() {
    const { classes, index } = this.props

    return (
      <div className="delete">
        <IconButton
          style={{
            background: theme.tabs.flows.deleteFlowButton,
            border: '1px solid',
            borderColor: theme.tabs.flows.deleteFlowButtonBorder,
            boxShadow: '0px 3px 6px var(--color-issabeline-grey)',
            width: 40,
            height: 40,
            position: 'absolute',
          }}
          aria-label="Delete"
          data-autotest={`button-delete-flow-${index}`}
          onClick={this.handleOpen}>
          <TrashIcon width="20px" height="20px" color={theme.tabs.flows.deleteFlowButtonIcon} />
        </IconButton>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          title={
            'Are you sure you want to delete ' + this.props.flow.name + ' flow?'
          }>
          <div className={classes.buttonsHolder}>
            <SubmitButton onClick={() => this.deleteFlow()} title="Delete" />
            <SubmitButton type="primary_with_border" onClick={this.handleClose} title="Cancel" />
          </div>
        </Modal>
      </div>
    )
  }
}

DeleteFlow.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  flows: state.flows,
  activeBot: state.activeBot,
})

export default withRouter(
  withStyles(styles, { withTheme: true })(connect(mapStateToProps)(DeleteFlow)),
)
