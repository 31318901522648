import React from 'react'
import { withStyles } from '@material-ui/core'
import MessageButton from './MessageButton'
import MessageQuickReplies from './MessageQuickReplies'
import ImageDropzone from '../../../../uiKit/inputs/FileDropzone'
import { connect } from 'react-redux'
import { PlusIcon } from '../../../../uiKit/icons/Icons.js'
import InputWithParams from '../../../../uiKit/InputWithParams/InputWithParams'
import ItemChipHelper from '../ItemChipHelper/ItemChipHelper'
import PropTypes from 'prop-types'
import ReactionBar from '../../../../uiKit/ReactionBar/ReactionBar'
import _ from 'lodash'

const styles = theme => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: 'auto',
  },
  view: {
    overflow: 'scroll',
    marginRight: '-17px',
    marginBottom: '-17px',
    paddingBottom: 12,
    display: 'flex',
  },
  message: {
    height: 'max-content',
    minWidth: '280px',
    margin: '5px',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    marginRight: 20,
    position: 'relative',
    '&:hover': {
      '& i': {
        display: 'flex',
        left: 220,
      },
    },
  },
  addElement: {
    minWidth: '280px',
    margin: '5px',
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    borderRadius: 10,
    marginRight: 20,
    position: 'relative',
    height: '356px',
  },
  addButton: {
    color: 'var(--color-button-primary)',
    fontWeight: 500,
    padding: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    margin: 'auto',
    borderRadius: '0 0 10px 10px',
  },
})
class Template extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  addElement() {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.attachment.payload.elements.push({
      title: '',
      subtitle: '',
      image_url: '',
      enableReaction: false,
      buttons: [],
    })
    this.props.updateMessage(newMessage)
  }

  handleChange(elemIndex, value, name) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.attachment.payload.elements[elemIndex][name] = value
    this.props.updateMessage(newMessage)
  }

  addNewButtonToElem(elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    if (newMessage.attachment.payload.elements[elemIndex].buttons == null) {
      newMessage.attachment.payload.elements[elemIndex].buttons = []
    }
    newMessage.attachment.payload.elements[elemIndex].buttons.push({
      type: 'postback',
      title: '',
      payload: '',
    })
    this.props.updateMessage(newMessage)
  }

  addReaction() {
    const newMessage = _.cloneDeep(this.props.message)
    newMessage.enableReaction = true
    this.props.updateMessage(newMessage)
  }

  removeReaction() {
    const newMessage = _.cloneDeep(this.props.message)
    newMessage.enableReaction = false
    this.props.updateMessage(newMessage)
  }

  addNewButton() {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    if (newMessage.attachment.payload.buttons == null) {
      newMessage.attachment.payload.buttons = []
    }
    newMessage.attachment.payload.buttons.push({
      type: 'postback',
      title: '',
      payload: '',
    })
    this.props.updateMessage(newMessage)
  }

  updateBtn(btn, btnIndex, elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    if (elemIndex !== null) newMessage.attachment.payload.elements[elemIndex].buttons[btnIndex] = btn
    else newMessage.attachment.payload.buttons[btnIndex] = btn
    this.props.updateMessage(newMessage)
  }

  deleteButton(btnIndex, elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    if (elemIndex !== null) newMessage.attachment.payload.elements[elemIndex].buttons.splice(btnIndex, 1)
    else newMessage.attachment.payload.buttons.splice(btnIndex, 1)
    this.props.updateMessage(newMessage)
  }

  isEnoughElements() {
    const numOfElem = 11
    return this.props.message.attachment.payload.elements.length < numOfElem
  }

  onDeleteElem(elemIndex) {
    const newMessage = JSON.parse(JSON.stringify(this.props.message))
    newMessage.attachment.payload.elements.splice(elemIndex, 1)
    this.props.updateMessage(newMessage)
  }

  render() {
    const elementsNum = this.props.message.attachment.payload.elements.length
    const { classes, rtl, scrollBlock } = this.props
    const showReactionBar = this.props.message.enableReaction

    return (
      <div
        style={{
          width: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            overflow: 'scroll',
            marginBottom: 32,
          }}>
          {this.props.message.attachment.payload.elements.map((elem, elemIndex) => (
            <div key={elem.id || elemIndex} className={classes.message}>
              <ItemChipHelper
                enableReaction={showReactionBar}
                addReaction={() => this.addReaction()}
                onDelete={() => {
                  elementsNum > 1 ? this.onDeleteElem(elemIndex) : this.props.onDelete()
                }}
              />
              <ImageDropzone
                value={elem.image_url}
                onChange={image_url => this.handleChange(elemIndex, image_url, 'image_url')}
                title="Replace image"
              />

              <InputWithParams
                onChange={value => this.handleChange(elemIndex, value, 'title')}
                value={elem.title}
                placeholder="Title"
                styles={{
                  height: 70,
                }}
                title="Title"
                rtl={rtl}
                scrollBlock={scrollBlock}
              />

              <InputWithParams
                onChange={value => this.handleChange(elemIndex, value, 'subtitle')}
                value={elem.subtitle}
                placeholder="Subtitle"
                styles={{
                  height: 70,
                }}
                title="Subtitle"
                rtl={rtl}
                scrollBlock={scrollBlock}
              />

              <div className="rep-btns-container">
                {elem.buttons != null &&
                  elem.buttons.map((btn, btnIndex) => (
                    <MessageButton
                      btn={btn}
                      key={btnIndex}
                      rtl={rtl}
                      onUpdate={btn => this.updateBtn(btn, btnIndex, elemIndex)}
                      onDelete={() => this.deleteButton(btnIndex, elemIndex)}
                    />
                  ))}
                {(elem.buttons == null || elem.buttons.length < 3) && (
                  <div className={classes.addButton} onClick={() => this.addNewButtonToElem(elemIndex)}>
                    add button
                  </div>
                )}
              </div>
            </div>
          ))}
          <div style={{ position: 'relative' }}>
            {this.isEnoughElements() && (
              <div onClick={() => this.addElement()} style={{ ...styles.addElement, display: 'flex' }}>
                <div style={{ margin: 'auto', color: '#c1c1c1' }}>
                  <PlusIcon width="20px" height="20px" color="grey" />
                </div>
              </div>
            )}
          </div>
        </div>
        {showReactionBar && <ReactionBar onDelete={() => this.removeReaction()} />}
        <MessageQuickReplies
          message={this.props.message}
          rtl={rtl}
          updateMessage={message => this.props.updateMessage(message)}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  classes: PropTypes.object,
  params: state.attributes,
})

export default connect(mapStateToProps)(withStyles(styles)(Template))
