import styled from 'styled-components'

const TrainButton = styled.button<{ isTrained: boolean; isDefault: boolean }>`
  border: 1px solid
    ${({ isTrained, isDefault }) => {
    if (isTrained) return 'var(--color-malachite-green)'
    if (isDefault) return 'var(--color-spun-pearl-grey)'
    return 'var(--color-button-primary)'
  }};
  color: ${({ isDefault }) => (isDefault ? 'var(--color-spun-pearl-grey)' : 'var(--color-button-primary)')};
  background: var(--color-white);
  border-radius: 10px;
  padding: 8px 16px;
  box-shadow: none;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  &:hover {
    background: var(--color-white);
  }
`

const TrainedIcon = styled.div`
  background: var(--color-malachite-green);
  border: 1px solid var(--color-malachite-green);
  border-radius: 10px;
  padding: 10px 34px;
  box-shadow: none;
  line-height: 1.4em;
  font-size: 0.875rem;
  min-height: 36px;
`

export { TrainButton, TrainedIcon }
