import { ROYAL_BLUE } from 'constants/colors'

export const styles = theme => ({
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
    minHeight: 0,
    margin: '16px -24px 0',
    padding: '0 24px',
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 50,
    padding: 4,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 24,
    fontSize: 14,
    minHeight: 0,
    '&:hover': {
      color: ROYAL_BLUE,
      opacity: 1,
    },
    '&:focus': {
      color: ROYAL_BLUE,
    },
    '& span': {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  tabSelected: {
    color: 'var(--color-button-primary)',
  },
  tabContainer: {
    padding: 0,
  },
  tabsIndicator: {
    backgroundColor: 'var(--color-button-primary)',
  },
  tabPanel: {
    padding: '24px 24px 32px 0',
    boxSizing: 'border-box',
    fontSize: 14,
    height: 'calc(100% - 96px)',
    overflow: 'auto',
    lineHeight: '20px',
    color: 'var(--color-oxford-blue)',
    marginRight: -24,
    position: 'relative',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 24px 0',
    borderTop: '1px solid #e8e8e8',
    margin: '0 -24px 0',
  },
  form: {
    height: 'calc(100% - 80px)',
  },
  loaderScreen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 345,
    width: 312
  }
})

export const buttonStyles = {
  borderRadius: 4,
  marginRight: 8,
  marginLeft: 'auto',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}
