import React, { FC, useContext, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { DateControlContext } from 'contexts/DateControlContext'
import { FunnelType } from 'models/FunnelTypes'
import ChartContainer from 'uiKit/ChartContainer'
import Headline from 'uiKit/texts/Headline'
import DateControl from 'uiKit/DateControlNew'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import { clearFunnelState } from 'tabs/analytics/actions/funnel'
import { getFunnel } from 'tabs/analytics/api/funnels'
import { getBot } from 'tabs/home/api/bots'
import { getFlows } from 'tabs/flowBuilder/api/flow'

import CompletionRateChart from '../CompletionRateChart'
import Transcripts from '../Transcripts'
import classes from './styles.module.scss'
import * as S from './Funnel.style'

interface MatchParams {
  botId: string
  funnelId: string
}

interface FunnelProps extends RouteComponentProps<MatchParams> {
  funnel: FunnelType
}

const tooltipText = 'Step completion rate shows a percentage of users who entered the flow and received this step atom.'

const Funnel: FC<FunnelProps> = ({ match, funnel }) => {
  const [selectedStepNumber, setSelectedStep] = useState(false)
  const [loader, setLoader] = useState(true)
  const { startDate, endDate } = useContext(DateControlContext)

  const formattedStartDate = startDate.format('YYYY-MM-DD')
  const formattedEndDate = endDate.format('YYYY-MM-DD')

  useEffect(() => {
    if (funnel !== null) {
      setLoader(false)
    }
  }, [funnel])

  useEffect(() => {
    getFunnel({
      botId: match.params.botId,
      funnelId: match.params.funnelId,
      from: formattedStartDate,
      to: formattedEndDate,
    })
  }, [startDate, endDate])

  useEffect(() => {
    getBot(match.params.botId)
    getFlows(match.params.botId)
    return clearFunnelState
  }, [])

  return (
    <div className={classes.container}>
      <div className={selectedStepNumber ? classes.chartContainerSelected : classes.chartContainer}>
        <S.HeadlineWrap>
          <Headline title={funnel?.name} />
          <DateControl />
        </S.HeadlineWrap>
        {loader ? (
          <LoaderScreen />
        ) : (
          <ChartContainer title="Completion rate" tooltipText={tooltipText}>
            <CompletionRateChart onSelect={setSelectedStep} selectedStepId={selectedStepNumber} />
          </ChartContainer>
        )}
      </div>

      {selectedStepNumber && (
        <Transcripts
          selectedStepNumber={selectedStepNumber}
          onClose={() => setSelectedStep(false)}
          fromDate={formattedStartDate}
          toDate={formattedEndDate}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: { funnel: FunnelType }) => ({
  funnel: state.funnel,
})

export default withRouter(connect(mapStateToProps)(Funnel))
