import React, { FC, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { getComments, getMoreComments } from '../../api/analytics'
import { getSortingParams, saveSortBy, saveSortDirection, SIZE } from '../../constants/commentsListConfig'
import CommentsFilter from '../CommentsFilter'
import Comment from '../Comment'
import LoaderSmall from '../../../../uiKit/loaders/loaderSmall'
import { isElementBottom } from '../../../../helpers/isElementBottom'

import classes from './styles.module.scss'
import {AllAnalyticsType, CommentsType} from '../../../../models/AnalyticsType'

interface Props {
  comments: CommentsType
  botId: any
  fromDate: string
  toDate: string
  selectedBar: number
}

const CommentsList: FC<Props> = ({ comments, botId, fromDate, toDate, selectedBar }) => {
  const { sortDirectionSaved, sortBySaved } = getSortingParams()
  const [sortDirection, setSortDirection] = useState(sortDirectionSaved || 'desc')
  const [sortBy, setSortBy] = useState(sortBySaved || 'date')
  const [page, setPage] = useState(0)
  const [loading, setLoader] = useState(false)

  useEffect(() => {
    clearState()
    if (fromDate && toDate && botId) {
      getComments(botId, fromDate, toDate, page, SIZE, sortBy, sortDirection, selectedBar)
    }
  }, [sortBy, sortDirection, fromDate, toDate, selectedBar, botId])

  const handleScroll = ({ target }: any) => {
    if (isElementBottom(target) && comments.hasNext) {
      if (loading) setLoader(true)
      setPage(page + 1)
      getMoreComments({
        botId,
        page,
        sortBy,
        from: fromDate,
        to: toDate,
        size: SIZE,
        sortDirection,
        score: selectedBar,
      }).then(() => setLoader(false))
    }
  }

  const handleSortBy = (sortBy: string) => {
    saveSortBy(sortBy)
    setSortBy(sortBy)
  }

  const handleSortDirection = (sortDirection: string) => {
    saveSortDirection(sortDirection)
    setSortDirection(sortDirection)
  }

  const clearState = () => {
    setPage(0)
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <CommentsFilter
          sortDirection={sortDirection}
          setSortDirection={handleSortDirection}
          sortBy={sortBy}
          setSortBy={handleSortBy}
        />
      </div>
      {loading ? (
        <div className={classes.loaderContainer}>
          <LoaderSmall showLoader={true} />
        </div>
      ) : (
        <div className={classes.commentsContainer} onScroll={handleScroll}>
          {comments?.feedbacks?.map(comment => (
            <Comment key={comment.date} data={comment} />
          ))}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: { analytics: AllAnalyticsType; activeBot: { id: number } }) => ({
  comments: state.analytics.comments,
  botId: state.activeBot?.id,
})

export default connect(mapStateToProps)(CommentsList)
