import React from 'react'
import Table from '@material-ui/core/Table'
import TableFooter from '@material-ui/core/TableFooter'

import { TableFooterPagination } from '../TableFooterPagination'

import { usePaginationHandler } from './usePaginationHandler'

import { PaginationTableContext } from './context'

export const PaginatedTable = props => {
  const paginationData = usePaginationHandler(props)
  // eslint-disable-next-line no-unused-vars
  const { endAdornment, ...propsWithoutEndAdornment } = props

  const value = React.useMemo(
    () => ({
      ...paginationData,
      colSpan: props.colSpan,
    }),

    [JSON.stringify(paginationData)],
  )

  return (
    <PaginationTableContext.Provider value={value} {...propsWithoutEndAdornment}>
      <Table className={props.className}>
        {props.children}
        <TableFooter>
          <TableFooterPagination colSpan={props.colSpan} />
        </TableFooter>
      </Table>
    </PaginationTableContext.Provider>
  )
}
