import { BASE_URL } from '../../../configs'
import {
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
  logoutOnRequestOrResponseBlob,
  alertSuccess,
} from '../../../api'

export function exportBot(botId) {
  return fetch(`${BASE_URL}/bot/${botId}/export`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  }).then(response => logoutOnRequestOrResponseBlob(response))
    .then(res => {
      if (res.error) {
        throw res.error
      } else {
        return res
      }
    })
}

export function uploadBot(botId, file) {
  return fetch(`${BASE_URL}/bot/${botId}/import`, {
    credentials: 'include',
    body: file,
    method: 'POST',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(res => {
      if (res.error) {
        throw res.error
      } else {
        alertSuccess('Chatbot version is updated successfully.')
      }
    })
}
