import React, { FC, useMemo } from 'react'
import { connect } from 'react-redux'

import { CrossIcon } from '../../../../uiKit/icons/CrossIcon'
import Tooltip from '../../../../uiKit/Tooltip'

import classes from './styles.module.scss'
import {FunnelType} from '../../../../models/FunnelTypes'

const tooltipId = 'atomNames-TooltipInfo'

interface Props {
  onClose: any
  atoms: any[]
  selectedStepNumber: number
  funnel: FunnelType
}

const TranscriptsHeader: FC<Props> = ({ onClose, atoms, selectedStepNumber, funnel }) => {
  const step = useMemo(() => {
    return funnel?.steps?.find(({ stepNumber }) => stepNumber === selectedStepNumber)
  }, [funnel, selectedStepNumber])

  const atomNames = useMemo(() => {
    return (
      step?.atomIds?.map(atomId => {
        return atoms?.find(({ id }) => id === atomId)?.name || ''
      }) || []
    )
  }, [funnel, atoms, selectedStepNumber])

  return (
    <div className={classes.header}>
      <div className={classes.titleWrap}>
        <span className={classes.button} onClick={onClose}>
          <CrossIcon color={'var(--color-spun-pearl-grey)'} size="18" />
        </span>
        <span className={classes.title}>Transcripts</span>
      </div>
      <div className={classes.stepName} data-for={tooltipId} data-tip>
        Step {step?.stepNumber}: {atomNames[0]}
      </div>
      <Tooltip id={tooltipId} place='bottom'>
        Step {step?.stepNumber}: {atomNames.join(' | ')}
      </Tooltip>
    </div>
  )
}

const mapStateToProps = (state: { funnel: FunnelType; atoms: any; activeBot: { id: any } }) => ({
  funnel: state.funnel,
  atoms: state.atoms,
  botId: state.activeBot.id,
})

export default connect(mapStateToProps)(TranscriptsHeader)
