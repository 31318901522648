import React, { FC, ChangeEvent } from 'react'

import * as S from './PromptForm.style'

interface PromptFormProps {
  onResetToDefault: () => void
  placeholderText: string
  promptText: string
  promptError?: string
  onPromptChange: (e: ChangeEvent<HTMLTextAreaElement>) => Promise<void>
  enableResetDefault: boolean
  displayResetNote: boolean
}

export const PromptForm: FC<PromptFormProps> = ({
  onResetToDefault,
  placeholderText,
  promptText,
  promptError,
  onPromptChange,
  enableResetDefault,
  displayResetNote,
}) => {
  return (
    <S.Container>
      <S.Title>Customize prompt</S.Title>
      <S.Form>
        <S.Textarea placeholder={placeholderText} value={promptText} onChange={onPromptChange} isError={promptError} />
        <S.Error>{promptError}</S.Error>
        <S.ButtonArea>
          <S.SecondaryButton onClick={onResetToDefault} disabled={!enableResetDefault}>
            Reset to default
          </S.SecondaryButton>
        </S.ButtonArea>
        {displayResetNote && (
          <S.SubHeader>
            If you intend to restore the prompt to its original settings, please click on the "Save changes" button.
          </S.SubHeader>
        )}
      </S.Form>
    </S.Container>
  )
}
