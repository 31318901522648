/* eslint-disable */
import React from 'react'

export const APICallIcon = (props) => {
  const { color } = props

  return (
    <svg width="32" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.70047 9.89875L2.80047 5.99875L6.70047 2.09875C7.09047 1.70875 7.09047 1.08875 6.70047 0.69875C6.31047 0.30875 5.69047 0.30875 5.30047 0.69875L0.710469 5.28875C0.320469 5.67875 0.320469 6.30875 0.710469 6.69875L5.30047 11.2987C5.69047 11.6887 6.31047 11.6887 6.70047 11.2987C7.09047 10.9087 7.09047 10.2887 6.70047 9.89875ZM13.3005 9.89875L17.2005 5.99875L13.3005 2.09875C12.9105 1.70875 12.9105 1.08875 13.3005 0.69875C13.6905 0.30875 14.3105 0.30875 14.7005 0.69875L19.2905 5.28875C19.6805 5.67875 19.6805 6.30875 19.2905 6.69875L14.7005 11.2987C14.3105 11.6887 13.6905 11.6887 13.3005 11.2987C12.9105 10.9087 12.9105 10.2887 13.3005 9.89875Z" fill={color}/>
    </svg>
  )
}
