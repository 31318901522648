import { TrainingTable } from './TrainingTable'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  nlpRequests: state.nlpRequests,
  activeBot: state.activeBot,
  intents: state.intents,
  intentsSize: state.intentsSize,
})

export default connect(mapStateToProps)(TrainingTable)
