import {getColorAnalytics75, getColorAnalytics40} from '../../../globalStylesHelper.js'

const colorAnalytics75 = getColorAnalytics75()
const colorAnalytics40 = getColorAnalytics40()

export const initialColors = [
  `${colorAnalytics40}`,
  `${colorAnalytics40}`,
  `${colorAnalytics40}`,
  `${colorAnalytics40}`,
  `${colorAnalytics40}`,
]

export const initialDataSet = {
  labels: ['5 stars', '4 stars', '3 stars', '2 stars', '1 star'],
  datasets: [
    {
      backgroundColor: initialColors,
      borderColor: `${colorAnalytics75}`,
      borderWidth: 1,
      hoverBackgroundColor: initialColors,
      hoverBorderColor: `${colorAnalytics75}`,
      data: null
    },
  ],
}

export const chartOptions = {
  maintainAspectRatio: false,
  animation: { duration: 0 },
  hover: { animationDuration: 0 },
  responsiveAnimationDuration: 0,
  legend: { display: false },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          min: 0,
        },
      },
    ],
  },
  onClick: null
}
