import classes from './styles.module.scss'
import React, { SetStateAction, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Input from '../../../../../uiKit/inputs/Input'
import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'
import { isStringEmpty } from '../../../../../helpers/isStringEmpty'
import { alertError, alertSuccess } from '../../../../../api'
import { updateWhatsApp360DialogConfigs } from '../../../api/whatsApp360Dialog'

interface Props {
  activeBot: any
}

const WhatsApp360Dialog: React.FC<Props> = ({ activeBot }) => {
  const [apiKey, setApiKey] = useState('')
  const [phone, setPhone] = useState('')
  const [isApiKeyError, setIsApiKeyError] = useState(false)
  const [isPhoneError, setIsPhoneError] = useState(false)

  const numbersOnly = /^[0-9\b]+$/

  useEffect(() => {
    setApiKey(activeBot.dialog360WhatsAppConfigs?.apiKey)
    setPhone(activeBot.dialog360WhatsAppConfigs?.phone)
  }, [activeBot.dialog360WhatsAppConfigs?.apiKey, activeBot.dialog360WhatsAppConfigs?.phone])

  const handleSubmit = async() => {
    if (isValidFields()) {
      const data = { apiKey: apiKey, phone: phone }

      try {
        const response = await updateWhatsApp360DialogConfigs(activeBot.id, data)
        if (response?.error) {
          alertError(response?.error?.message || 'Failed. Try it later')
        } else {
          alertSuccess('WhatsApp 360Dialog Provider connected successfully')
        }
      } catch (e) {
        setApiKey('')
        setPhone('')
        alertError('Failed, try it later')
      }
    }
  }

  const handleApiKeyChange = (event: { target: { value: SetStateAction<null> } }) => {
    setApiKey(event.target.value)
  }

  const handlePhoneChange = (event: { target: { value: SetStateAction<null> } }) => {
    if (event.target.value.toString() === '' || numbersOnly.test(event.target.value.toString())) {
      setPhone(event.target.value)
    }
  }

  const isValidFields = () => {
    const apiKeyError = isStringEmpty(apiKey)
    setIsApiKeyError(apiKeyError)
    const phoneError = !numbersOnly.test(phone)
    setIsPhoneError(phoneError)

    return !(apiKeyError || phoneError)
  }

  return (
    <>
      <div className={classes.inputWrap}>
        <Input
          error={isApiKeyError}
          title="API Key"
          value={apiKey || ''}
          maxLength={255}
          autofocus
          placeholder="Enter WhatsApp 360Dialog API key"
          onChange={handleApiKeyChange}
        />
        <p className={classes.description}>
          Guides to retrieve API Keys for{' '}
          <a href="https://docs.360dialog.com/api-key" target="_blank" rel="noreferrer">
            production
          </a>{' '}
          and
          <a href="https://docs.360dialog.com/whatsapp-api/whatsapp-api/sandbox" target="_blank" rel="noreferrer">
            {' '}
            test
          </a>
        </p>
        {isApiKeyError && <p className={classes.errorAlert}>API Key cannot be an empty field</p>}
        <Input
          error={isPhoneError}
          title="Phone Number"
          value={phone || ''}
          maxLength={15}
          autofocus
          placeholder="With number code — for example, 4930609859535"
          onChange={handlePhoneChange}
        />
        <p className={classes.description}>
          Your business phone number. Actual sandbox phone number could be found{' '}
          <a href="https://docs.360dialog.com/whatsapp-api/whatsapp-api/sandbox" target="_blank" rel="noreferrer">
            here
          </a>
        </p>
        {isPhoneError && <p className={classes.errorAlert}>Phone cannot be an empty field</p>}
      </div>
      <div className={classes.buttonHolder}>
        <SubmitButton
          title="Connect"
          type={'secondary'}
          size="sm"
          onClick={handleSubmit}
          disabled={!apiKey?.length || !phone?.length}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state: { activeBot: any }) => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(WhatsApp360Dialog)
