/**
 * Created by serhiy on 05.03.18.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { getIntents } from '../api/nlp'
import { getContextsPages } from '../api/context'
import TriggerItem from './TriggerItem'
import { PlusIcon } from '../../../uiKit/icons/Icons.js'
import Loader from '../../../uiKit/loaders/loader'
import Pagination from '../../../uiKit/table/Pagination'
import NoData from './NoNlpData.js'
import { clearContexts } from '../actions/contexts'

const styles = theme => ({
  root: {
    width: '100%',
    overflow: 'visible',
  },
  table: {
    minWidth: 700,
    overflow: 'visible',
  },
  createTriggerButton: {
    float: 'right',
  },
  mainSelected: {
    display: 'flex',
    margin: '20px 0',
    width: '100%',
    justifyContent: 'space-between',
    background: 'var(--color-white)',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: 10,
  },
  selectItem: {
    flexBasis: '30%',
    padding: 24,
  },
  title: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 24,
    color: 'var(--color-text-primary)',
  },
  createIntentButton: {
    background: 'var(--color-button-secondary)',
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
    height: 40,
    width: 40,
    padding: 0,
    minWidth: 40,
    borderRadius: 25,
  },
  addTitle: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: 14,
    color: 'var(--color-text-primary)',
  },
})

class Triggers extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      rowsPerPage: 10,
      open: false,
      modalTriggerId: 0,
      currentAtom: null,
      newAtom: null,
      intent: null,
      newContext: false,
    }

    clearContexts()
    getIntents(this.props.match.params.botId, false)
    getContextsPages(props.match.params.botId, this.state.page)
  }

  componentWillReceiveProps() {
    if (this.props.contexts != null) {
      if (this.props.contexts.length == 1 && this.props.contextSize > 10) {
        if (this.state.page === 0) {
          getContextsPages(this.props.match.params.botId, this.state.page)
        }
      }
      if (this.props.contexts.length == 0 && this.props.contextSize > 0) {
        if (this.state.page > 0) {
          const page = this.state.page - 1
          getContextsPages(this.props.match.params.botId, page)
        }
      }
    }
  }

  handleChange = (name, value, id) => {
    this.setState({
      [name]: value,
    })
    this.createTrigger(id)
  }

  createTrigger = () => {
    this.setState({
      newContext: true,
    })
  }

  changeNewContext = () => {
    this.setState({
      newContext: false,
    })
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
    getContextsPages(this.props.match.params.botId, page)
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  render() {
    const { classes, contexts } = this.props
    const { rowsPerPage, page, newContext } = this.state

    const context = {
      previousAtom: this.state.currentAtom,
      intent: this.state.intent,
      currentAtom: this.state.newAtom,
    }

    return (
      <div style={{ height: '100%' }}>
        <div style={{ height: '80%' }}>
          <div style={{ height: '100%' }}>
            <div className={classes.root} style={{ height: '100%' }}>
              <h1 className={classes.title} style={{ marginRight: 32 }}>
                Triggers
              </h1>

              {!contexts ? (
                <Loader />
              ) : !contexts.length && !newContext ? (
                <NoData
                  description={
                    'You have no Triggers yet. Add a new Trigger to make your bot answer more complicated questions.'
                  }
                  onClick={this.createTrigger}
                  buttonTitle={'Add Trigger'}
                />
              ) : (
                <div className={classes.tableWrapper}>
                  {
                    <div>
                      {!!contexts.length && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: 260,
                            justifyContent: 'space-between',
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              flexBasis: '50%',
                            }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}>
                              <Button
                                className={classes.createIntentButton}
                                onClick={this.createTrigger}
                                variant="raised">
                                <PlusIcon
                                  height="13px"
                                  width="13px"
                                  color="var(--color-text-on-secondary)"
                                />
                              </Button>
                              <p
                                className={classes.addTitle}
                                style={{ margin: '0 0 0 16px' }}>
                                Add new trigger
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className={classes.table}>
                        {newContext && (
                          <TriggerItem
                            newContext={newContext}
                            changeNewContext={this.changeNewContext}
                            context={context}
                            modalTriggerId={context.id}
                            contextSize={this.props.contextSize}
                          />
                        )}
                        {!!contexts.length && (
                          <div>
                            {contexts.map(context => (
                              <div key={context.id}>
                                <TriggerItem
                                  context={context}
                                  modalTriggerId={context.id}
                                  contextSize={this.props.contextSize}
                                />
                              </div>
                            ))}
                            <Pagination
                              count={this.props.contextSize}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onChangePage={this.handleChangePage}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Triggers.defaultProps = {
  activeBot: {},
}

Triggers.propTypes = {
  classes: PropTypes.object.isRequired,
  activeBot: PropTypes.object,
}

const mapStateToProps = state => ({
  intents: state.intents,
  contexts: state.contexts,
  activeBot: state.activeBot,
  contextSize: state.contextSize,
})

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(Triggers)),
)
