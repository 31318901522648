import React, { useState } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import withStyles from '@material-ui/core/styles/withStyles'
import { PageMenu } from '../PageMenu'

import { cellStyles, styles } from './styles'
import { EditPage } from '../EditPage'
import Modal from 'uiKit/Modal'

interface Props {
  classes: any
  page: any
  botId: number
}

export const View: React.FC<Props> = props => {
  const { classes } = props
  const [isOpenEditPage, setIsOpenEditPage] = useState(false)

  const closeEditModalHandle = () => {
    setIsOpenEditPage(false)
  }

  const openEditModalHandle = () => {
    setIsOpenEditPage(true)
  }

  return (
    <>
      <TableRow>
        <TableCell style={cellStyles.image} className={classes.imageCell}>
          <img
            src={props.page.pictureUrl}
            alt="page image"
            onError={event => {
              // @ts-ignore
              event.target.src = '/images/platform/default_profile.jpeg'
              // @ts-ignore
              event.onerror = null
            }}
          />
        </TableCell>
        <TableCell style={cellStyles.name}>{props.page.pageName}</TableCell>
        <TableCell style={cellStyles.languages}>{props.page?.defaultLanguage?.fullName}</TableCell>
        <TableCell style={cellStyles.connectedBy}>{props.page.connectedBy}</TableCell>
        <TableCell style={cellStyles.menu}>
          <PageMenu page={props.page} openEditModalHandle={openEditModalHandle} />
        </TableCell>
      </TableRow>

      {/*TODO move modal to Facebook.js */}
      <Modal
        classes={{
          paper: classes.pageModalPaper,
        }}
        open={isOpenEditPage}
        onClose={closeEditModalHandle}
        title="Edit facebook page chatbot settings"
        customStyle={{ height: '100%', maxHeight: '550px' }}>
        <EditPage botId={props.botId} closeEditModal={closeEditModalHandle} page={props.page} />
      </Modal>
    </>
  )
}

export const FacebookActivePagesTableBodyRow = withStyles(styles)(View)
