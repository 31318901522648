import React from 'react'

const DisconnectPageModalContext = React.createContext({
  page: null,
  isOpenDisconnectPageModal: false,
  setIsOpenDisconnectPageModal: null,
  handleOpenDisconnectPageModal: null
})

const DisconnectPageModalProvider = props => {
  const [page, setPage] = React.useState({})
  const [isOpenDisconnectPageModal, setIsOpenDisconnectPageModal] = React.useState(false)

  const handleOpenDisconnectPageModal = page => {
    setPage(page)
    setIsOpenDisconnectPageModal(true)
  }

  const value = React.useMemo(
    () => ({
      page,
      isOpenDisconnectPageModal,
      setIsOpenDisconnectPageModal,
      handleOpenDisconnectPageModal,
    }),
    [page, isOpenDisconnectPageModal],
  )

  return <DisconnectPageModalContext.Provider {...props} value={value} />
}

function useDisconnectPageModalContext() {
  const context = React.useContext(DisconnectPageModalContext)

  if (!context) {
    throw new Error('useDisconnectPageModalContext must be inside DisconnectPageModalProvider')
  }

  return context
}

export { DisconnectPageModalProvider, useDisconnectPageModalContext }
