import React from 'react'

import AppearanceTab from '../tabs/widget/components/AppearanceTab'
import WelcomeScreen from '../tabs/widget/components/WelcomeScreenTab'
import Greeting from '../tabs/widget/components/GreetingTab'
import Integration from '../tabs/widget/components/IntegrationTab'

export const widgetRoutes = [
  {
    path: 'appearance',
    name: 'Appearance',
    component: () => <AppearanceTab/>,
  },
  {
    path: 'welcomeScreen',
    name: 'Welcome screen',
    component: () => <WelcomeScreen/>,
  },
  {
    path: 'greeting',
    name: 'Greeting',
    component: () => <Greeting/>,
  },
  {
    path: 'integration',
    name: 'Integration',
    component: () => <Integration/>,
  }
]
