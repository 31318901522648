import React, { useContext, useState } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { TrashIcon } from '../../../../uiKit/icons/TrashIcon'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import AttributeSelect from '../../../../uiKit/AttributeSelect/AttributeSelect'
import SecondaryButton from '../../../../uiKit/buttons/SecondaryButton/SecondaryButton'

import { styles } from './styles'
import InputAutosuggest from '../../../../uiKit/InputAutosuggest/InputAutosuggest'
import { CHATBOT, DEFAULT } from '../../../../constants/attributeTypes'
import MessageTemplate from '../MessageTemplate/MessageTemplate'
import { MessagesContext } from '../MessagesWrap/MessagesWrap'
import { alertError } from '../../../../api'

const SetAttribute = props => {
  const { classes, message, updateMessage, onDelete } = props

  const [focus, setFocus] = useState(null)
  const { saveTime } = useContext(MessagesContext)

  const handleUpdateAttribute = (e, index) => {
    const isDuplicate = message.attributes
      .filter((atom, i) => i !== index)
      .some(atom => atom.id === e.value)

    if (isDuplicate) {
      alertError('This attribute is already used.')
    } else {
      message.attributes = deepCopyFunction(message?.attributes)?.map((attribute, i) => {
        if (i === index) {
          attribute.id = e?.value
          attribute.name = e?.label
        }

        return attribute
      })
      updateMessage(message)
      setFocus(false)
    }
  }

  const handleUpdateAttributeValue = (e, index) => {
    message.attributes = deepCopyFunction(message?.attributes)?.map((attribute, i) => {
      if (i === index) {
        attribute.value = e.target.value
      }
      return attribute
    })

    updateMessage(message)
  }

  const handleAddAttribute = () => {
    const attributesUpdate = deepCopyFunction(message?.attributes) || []
    const newAttribute = { id: null, value: null, name: null }

    attributesUpdate.push(newAttribute)
    message.attributes = attributesUpdate
    updateMessage(message)
  }

  const handleDeleteAttribute = index => {
    message.attributes = deepCopyFunction(message?.attributes).filter((attribute, i) => index !== i)
    updateMessage(message)
  }

  return (
    <MessageTemplate
      title={'Set attribute'}
      tooltipText={'Set a value for an existing chatbot attribute or add a new one'}
      onDelete={onDelete}>
      <>
        <div className={classes.body}>
          <div className={classes.labels}>
            <p>Name</p>
            <p>Value</p>
          </div>
          {message?.attributes?.map((attribute, index) => (
            <div className={classes.attributeWrap} key={attribute?.id || index}>
              <AttributeSelect
                focus={focus === index || !attribute.id}
                value={attribute?.id}
                onChange={e => handleUpdateAttribute(e, index)}
                onBlur={() => setFocus(false)}
                onClick={() => setFocus(index)}
                required={true}
                displayOptions={[DEFAULT, CHATBOT]}
                containerStyle={{ width: '50%' }}
                isClearable={false}
                defaultAttributes={['language', 'close_conversation', 'conversation_outcome']}
                saveTime={saveTime}
                index={index}
              />
              <InputAutosuggest
                attributeId={attribute?.id}
                value={attribute?.value}
                placeholder={'none'}
                style={{ width: '50%' }}
                onChange={e => handleUpdateAttributeValue(e, index)}
                index={index}
              />
              {message?.attributes?.length > 1 && (
                <u className={classes.iconWrap} onClick={() => handleDeleteAttribute(index)}>
                  <TrashIcon />
                </u>
              )}
            </div>
          ))}
        </div>
        <div className={classes.footer}>
          <SecondaryButton title="Add attribute" onClick={handleAddAttribute} dataAutotest={'button-add-attribute'}/>
        </div>
      </>
    </MessageTemplate>
  )
}

SetAttribute.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  attributes: state.attributes,
  activeBotId: state.activeBot?.id,
})

export default withRouter(withStyles(styles)(connect(mapStateToProps)(SetAttribute)))
