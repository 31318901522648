import React, { FC, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import FunnelsHeader from '../FunnelsHeader'
import FunnelsTable from '../FunnelsTable'
import FunnelModal from '../FunnelModal'
import DeleteModal from '../DeleteModal'
import NoChartData from 'uiKit/NoChartData'
import LoaderSmall from 'uiKit/loaders/loaderSmall'

import { FunnelType } from 'models/FunnelTypes'
import { DateControlContext } from 'contexts/DateControlContext'
import { getFunnels } from '../../api/funnels'

import * as S from './FunnelsWrap.style'

interface Props {
  funnels: FunnelType[]
  scrollToBottom: () => void
  botId: number
}

const FunnelsWrap: FC<Props> = ({ funnels, scrollToBottom, botId }) => {
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedFunnel, setFunnel] = useState<null | FunnelType>(null)
  const [loading, setLoading] = useState(false)
  const { startDate, endDate } = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    getFunnels(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')).finally(() => setLoading(false))
  }, [endDate])

  const handleToggleDeleteModal = (open: boolean, selectedFunnel: null) => {
    setFunnel(selectedFunnel)
    setOpenDeleteModal(open)
  }

  const handleToggleFunnelModal = (open: boolean, selectedFunnel: FunnelType) => {
    setFunnel(selectedFunnel)
    setOpenAddModal(open)
  }

  const renderFunnels = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true} />
        </S.LoaderContainer>
      )
    } else if (funnels?.length) {
      return <FunnelsTable onSelect={handleToggleFunnelModal} onOpenDeleteModal={handleToggleDeleteModal} />
    } else {
      return <NoChartData text="You have not created any funnels yet" />
    }
  }

  return (
    <S.Container>
      <FunnelsHeader onOpen={() => setOpenAddModal(true)} />
      <S.TableWrap>{renderFunnels()}</S.TableWrap>

      {openAddModal && (
        <FunnelModal
          open={openAddModal}
          onClose={() => handleToggleFunnelModal(false, null)}
          selectedFunnel={selectedFunnel}
          fromDate={startDate.format('YYYY-MM-DD')}
          toDate={endDate.format('YYYY-MM-DD')}
          scrollToBottom={scrollToBottom}
        />
      )}

      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          selectedFunnel={selectedFunnel}
          onClose={() => handleToggleDeleteModal(false, null)}
        />
      )}
    </S.Container>
  )
}

const mapStateToProps = (state: { funnels: FunnelType[]; activeBot: any }) => ({
  funnels: state.funnels,
  botId: state.activeBot?.id,
})

export default connect(mapStateToProps)(FunnelsWrap)
