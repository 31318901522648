import styled from 'styled-components'

const RadioButtonGroup = styled.div`
  width: 300px;
  display: flex;
  margin: 24px 0;
`

const Option = styled.div<{ isActive: boolean }>`
  border: 1px solid ${({ isActive }) => (isActive ? 'var(--color-button-secondary)' : 'var(--color-ghost-grey)')};
  background-color: ${({ isActive }) => (isActive ? 'var(--color-button-secondary)' : 'var(--color-white)')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 115px;
  color: ${({ isActive }) => (isActive ? 'var(--color-text-on-secondary)' : 'var(--color-text-secondary)')};
  flex-grow: 1;
  flex-basis: 0;
  font-size: 14px;
  cursor: pointer;
  &:first-child{
    border-radius: 10px 0 0 10px;
  }
  &:last-child{
    border-radius: 0 10px 10px 0;
  }
`

export { RadioButtonGroup, Option }
