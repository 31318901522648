import React, { FC, useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Chart from '../Chart'
import CommentsList from '../CommentsList'
import AnalyticsHeader from '../AnalyticsHeader'
import NoChartData from 'uiKit/NoChartData'
import LoaderSmall from 'uiKit/loaders/loaderSmall'

import { AllAnalyticsType, AnalyticsType } from 'models/AnalyticsType'
import { DateControlContext } from 'contexts/DateControlContext'
import { getRatingDistribution, getComments } from '../../api/analytics'

import * as S from './AnalyticsWrap.style'

interface Props {
  analytics: AnalyticsType
  botId: number
}

const AnalyticsWrap: FC<Props> = ({ analytics, botId }) => {
  const [selectedBar, setSelectedBar] = useState(null)
  const [loading, setLoading] = useState(false)
  const { startDate, endDate } = useContext(DateControlContext)

  useEffect(() => {
    setLoading(true)
    Promise.all([
      getRatingDistribution(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')),
      getComments(botId, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')),
    ]).finally(() => setLoading(false))
  }, [endDate])

  const renderAnalytics = () => {
    if (loading) {
      return (
        <S.LoaderContainer>
          <LoaderSmall showLoader={true} />
        </S.LoaderContainer>
      )
    } else if (analytics?.totalFeedbacks) {
      return (
        <>
          {analytics?.distribution && (
            <Chart data={analytics.distribution} selectedBar={selectedBar} handleSelectedBar={setSelectedBar} />
          )}
          <CommentsList
            selectedBar={selectedBar}
            fromDate={startDate.format('YYYY-MM-DD')}
            toDate={endDate.format('YYYY-MM-DD')}
          />
        </>
      )
    } else {
      return <NoChartData text="No data during selected time range" />
    }
  }

  return (
    <S.Container>
      <AnalyticsHeader />

      <S.ChartWrap>{renderAnalytics()}</S.ChartWrap>
    </S.Container>
  )
}

const mapStateToProps = (state: { analytics: AllAnalyticsType; activeBot: any }) => ({
  analytics: state.analytics.analytics,
  botId: state.activeBot?.id,
})

export default connect(mapStateToProps)(AnalyticsWrap)
