import React, { useState, SetStateAction, useEffect, useRef } from 'react'

import ApiKeysTable from '../ApiKeysTable'
import DeleteApiKeysModal from '../DeleteApiKeysModal'
import LoaderOverlay from 'uiKit/loaders/loaderOverlay'
import Input from 'uiKit/inputs/Input'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import { createApiKey, getApiKeys } from '../../../api/apiKeys'
import {
  ALLOWED_SYMBOLS_ALERT,
  API_KEYS_LIMIT_ALERT,
  MAX_LENGTH_ALERT,
  UNIQUE_NAME_ALERT,
} from '../../../constants/apiKeysAlerts'
import { alertError, alertSuccess } from 'api'

import * as S from './ApiKeys.style'

interface Props {
  activeBot: any
  isExpanded: boolean
}

export const ApiKeys: React.FC<Props> = ({ activeBot, isExpanded }) => {
  const [apiKeyName, setApiKeyName] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [apiKeysList, setApiKeysList] = useState([])
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedApiKey, setSelectedApiKey] = useState(null)
  const containerEndRef = useRef(null)

  useEffect(() => {
    updateApiKeysList()
  }, [])

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        containerEndRef.current?.scrollIntoView({ behavior: 'smooth' })
      }, 200)
    }
  }, [isExpanded])

  const updateApiKeysList = () => {
    getApiKeys(activeBot.id)
      .then(res => setApiKeysList(res || []))
      .catch(error => alertError(error?.message || 'Fail. Try it later'))
  }

  const handleSubmit = () => {
    if (!error && apiKeysList.length < 50 && apiKeyName.length) {
      setLoading(true)
      createApiKey(activeBot.id, apiKeyName)
        .then(res => {
          setApiKeyName('')
          setApiKeysList(res)
          alertSuccess('API key is created successfully')
        })
        .then(() => containerEndRef.current?.scrollIntoView({ behavior: 'smooth' }))
        .catch(error => alertError(error?.message || 'Fail. Try it later'))
        .finally(() => setLoading(false))
    }
  }

  const handleChange = (event: { target: { value: SetStateAction<null> }; preventDefault: () => null }) => {
    validateApiKeys(event.target.value)

    if (event.target.value.length > 64) {
      event.preventDefault()
    } else {
      setApiKeyName(event.target.value)
    }
  }

  const validateApiKeys = value => {
    let error
    const isNotUniqueName = apiKeysList.some(key => key.name === value)

    if (!/^[a-z0-9_-]*$/.test(value)) {
      error = ALLOWED_SYMBOLS_ALERT
    }
    if (isNotUniqueName) {
      error = UNIQUE_NAME_ALERT
    }
    setError(error)
  }

  const handleToggleDeleteModal = (open: boolean, selectedApiKey: null) => {
    setSelectedApiKey(selectedApiKey)
    setOpenDeleteModal(open)
  }

  return (
    <>
      <S.InputWrap>
        <Input
          error={error}
          value={apiKeyName}
          maxLength={255}
          handleEnterPress={handleSubmit}
          placeholder="Name your API key"
          onChange={handleChange}
        />
        {error && <S.ErrorAlert>{error}</S.ErrorAlert>}
        {apiKeyName.length >= 64 && <S.MaxLengthAlert>{MAX_LENGTH_ALERT}</S.MaxLengthAlert>}
      </S.InputWrap>
      <S.ButtonWrap>
        <SubmitButton
          title="Create API key"
          type={'secondary'}
          size="sm"
          onClick={handleSubmit}
          disabled={error || loading || apiKeysList.length >= 50 || !apiKeyName.length}
        />
        {apiKeysList.length >= 50 && <S.ErrorAlert>{API_KEYS_LIMIT_ALERT}</S.ErrorAlert>}
      </S.ButtonWrap>
      {!!apiKeysList.length && <ApiKeysTable apiKeysList={apiKeysList} onOpenDeleteModal={handleToggleDeleteModal} />}
      {openDeleteModal && (
        <DeleteApiKeysModal
          open={openDeleteModal}
          selectedApiKey={selectedApiKey}
          updateApiKeysList={updateApiKeysList}
          onClose={() => handleToggleDeleteModal(false, null)}
        />
      )}
      {loading && <LoaderOverlay />}
      <div ref={containerEndRef} />
    </>
  )
}
