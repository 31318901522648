import React, { useMemo, useState } from 'react'
import classes from './styles.module.scss'
import JSONPretty from 'react-json-pretty'
import HeadersPreview from '../HeadersPreview/HeadersPreview'

interface Props {
  data: any
}

type TabType = 'Response Headers' | 'Response Body' | 'Request Headers' | 'Request Body'

const tabs: TabType[] = ['Response Headers', 'Response Body', 'Request Headers', 'Request Body']

const ResponseTab: React.FC<Props> = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState('Response Headers')
  const disable = data.request.method === 'GET'

  const handleSelectTab = (tab: TabType) => {
    if (disable && tab === tabs[3]) return
    setSelectedTab(tab)
  }

  const statusCodeColor = useMemo(() => {
    switch (data.response.statusCodeValue.toString()[0]) {
      case '1':
        return 'var(--color-klein-blue)'
      case '2':
        return 'var(--color-camarone-green)'
      case '3':
        return 'var(--color-gem-blue)'
      case '4':
        return 'var(--color-cinnamon-yellow)'
      default:
        return 'var(--color-tamarillo-red)'
    }
  }, [data.response.statusCodeValue])

  return (
    <div className={classes.container}>
      <div className={classes.statusCode} style={{ color: statusCodeColor }}>
        {data.response.statusCodeValue} {data.response.statusCode}
      </div>
      <div className={classes.body}>
        <div className={classes.tabs}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={selectedTab === tab ? classes.activeTab : classes.tab}
              style={{
                color: disable && tab === tabs[3] && 'var(--color-oxford-blue)80',
              }}
              onClick={() => handleSelectTab(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div className={classes.input}>
          {selectedTab === tabs[0] && <HeadersPreview headers={data?.response?.headers} />}

          {selectedTab === tabs[1] && <JSONPretty id="json-pretty" data={data?.response?.body} />}

          {selectedTab === tabs[2] && <HeadersPreview headers={data?.request?.headers} />}

          {selectedTab === tabs[3] && <JSONPretty id="json-pretty" data={data?.request?.body} />}
        </div>
      </div>
    </div>
  )
}

export default ResponseTab
