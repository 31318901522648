import React, { useMemo } from 'react'
import { connect } from 'react-redux'

import Paragraph from '../../../../../uiKit/texts/Paragraph'
import { WidgetSettingsType } from '../../../../../models/WidgetSettingsTypes'

import CopySnippet from '../../CopySnippet'

import Urls from '../../../components/Urls'
import { updateWidget } from '../../../api/widget'
import { deepCopyFunction } from '../../../../../helpers/deepCopyFunction'


interface Props {
    widgetSettings: WidgetSettingsType
    activeBot: any
}

export const WidgetSubtab: React.FC<Props> = ({ widgetSettings, activeBot }): JSX.Element => {
  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])


  const handleUpdateWidgetSettings = (value:string, field:string) => {
    widgetCopy[field] = value
    updateWidget(activeBot.id, widgetCopy)
  }

  return (
    <div>
      <Paragraph style={{ margin: '0 0 8px 0' }}>Provide your website URL</Paragraph>
      <Urls
        handleChange={(value:string) => handleUpdateWidgetSettings(value, 'websiteUrls')}
        websiteUrl={widgetSettings?.websiteUrls}
      />
      <CopySnippet/>
    </div>
  )
}

const mapStateToProps = (state: { widgetSettings: WidgetSettingsType, activeBot: any }) => ({
  widgetSettings: state.widgetSettings,
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(WidgetSubtab)
