/* eslint-disable */
import React from 'react'

export class ThreeIcon extends React.Component {
  render() {
    return (
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33331 10C8.33331 9.07952 9.0795 8.33333 9.99998 8.33333C10.9205 8.33333 11.6666 9.07952 11.6666 10C11.6666 10.9205 10.9205 11.6667 9.99998 11.6667C9.0795 11.6667 8.33331 10.9205 8.33331 10Z"
          fill="var(--color-oxford-blue)"
          fillOpacity="0.7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1667 10C14.1667 9.07952 14.9129 8.33333 15.8334 8.33333C16.7538 8.33333 17.5 9.07952 17.5 10C17.5 10.9205 16.7538 11.6667 15.8334 11.6667C14.9129 11.6667 14.1667 10.9205 14.1667 10Z"
          fill="var(--color-oxford-blue)"
          fillOpacity="0.7"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 10C2.5 9.07952 3.24619 8.33333 4.16667 8.33333C5.08714 8.33333 5.83333 9.07952 5.83333 10C5.83333 10.9205 5.08714 11.6667 4.16667 11.6667C3.24619 11.6667 2.5 10.9205 2.5 10Z"
          fill="var(--color-oxford-blue)"
          fillOpacity="0.7"
        />
      </svg>
    )
  }
}
