import React from 'react'

export const LanguageArrowUp = () => {
  return (
    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0135 6.97546L6.48634 2.00009L2.01368 7.02457"
        stroke="var(--color-button-primary)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  )
}
