import Modal from '@material-ui/core/Modal'
import { connect } from 'react-redux'
import React, { FC, ReactEventHandler } from 'react'

import { CrossIcon } from '../../../../uiKit/icons/CrossIcon'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'

import classes from './styles.module.scss'
import { deleteFunnel } from '../../api/funnels'
import {FunnelType} from '../../../../models/FunnelTypes'

interface Props {
  botId: any
  open: boolean
  selectedFunnel: FunnelType
  onClose: ReactEventHandler
}

const DeleteModal: FC<Props> = ({ botId, open, selectedFunnel, onClose }) => {
  const handleDelete = (event: any) => {
    deleteFunnel({botId, funnelId: selectedFunnel.id})
    onClose(event)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={classes.container}>
        <p className={classes.title}>Are you sure you want to delete {selectedFunnel.name} funnel?</p>
        <div className={classes.closeButton} onClick={onClose}>
          <CrossIcon color={'var(--color-spun-pearl-grey)'} />
        </div>
        <div className={classes.buttonsWrap}>
          <div className={classes.deleteButton} onClick={handleDelete}>
            Delete
          </div>
          <SubmitButton onClick={onClose} title="Cancel" />
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state: { activeBot: { id: any } }) => ({
  botId: state.activeBot?.id,
})

export default connect(mapStateToProps)(DeleteModal)
