export const styles = () => ({
  category: {
    height: 36,
    padding: '8px 12px',
    color: 'var(--color-oxford-blue)',
    display: 'block',
    fontSize: 14,
  },
  option: {
    margin: '5px 12px',
    color: 'var(--color-white)',
    padding: '1px 8px',
    borderRadius: 50,
    width: 'fit-content',
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tooltip: {
    maxWidth: '100%',
    width: '300px',
    boxSizing: 'border-box',
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: '10px',
    zIndex: 9999,
    opacity: 1,
    textAlign: 'start',
    fontWeight: 'normal',
    padding: 10,
    backgroundColor: '#616581',
  },
  optionWrap: {
    width: '100%',
    height: 33,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
})
