export function deepCompare(a, b) {//NOSONAR
  if (typeof a == 'object' && a != null && typeof b == 'object' && b != null) {
    let key
    const count = [0, 0]
    for (key in a) count[0]++
    // eslint-disable-next-line no-redeclare
    for (key in b) count[1]++
    if (count[0] - count[1] != 0) {
      return false
    }
    // eslint-disable-next-line no-redeclare
    for (key in a) {
      if (!(key in b) || !deepCompare(a[key], b[key])) {
        return false
      }
    }
    // eslint-disable-next-line no-redeclare
    for (key in b) {
      if (!(key in a) || !deepCompare(b[key], a[key])) {
        return false
      }
    }
    return true
  } else {
    return a === b
  }
}
