import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import withStyles from '@material-ui/core/styles/withStyles'

import { styles } from './styles'
import { FacebookActivePagesTableBodyRow } from './FacebookActivePagesTableBodyRow'
import { usePaginationTableContext } from 'uiKit/PaginatedTable/context'

interface Props {
  classes: any
  botId: number
}

export const View: React.FC<Props> = props => {
  const { classes } = props
  const { rows } = usePaginationTableContext()

  return (
    <TableBody className={classes.body}>
      {rows.map(page => (
        <FacebookActivePagesTableBodyRow key={page.pageId} page={page} botId={props.botId} />
      ))}
    </TableBody>
  )
}

export const FacebookActivePagesTableBody = withStyles(styles)(View)
