import React from 'react'
import { connect } from 'react-redux'

import AiChatCopySnippet from '../../AiChatCopySnippet'

export const AiChatSubtab = (): JSX.Element => {

  return (
    <div>
      <AiChatCopySnippet/>
    </div>
  )
}

const mapStateToProps = (state: { aiChatConfig: any, activeBot: any }) => ({
  aiChatConfig: state.aiChatConfig,
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(AiChatSubtab)
