import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles } from './styles'
import { PlusIcon } from '../../icons/PlusIcon'

const SecondaryButton = props => {
  const { classes, onClick, styles, title, dataAutotest, plusIconColor } = props

  return (
    <div
      className={classes.button}
      onClick={e => onClick && onClick(e)}
      style={styles}
      data-autotest={dataAutotest}>
      <span>
        <PlusIcon color={plusIconColor}/> {title}
      </span>
    </div>
  )
}

SecondaryButton.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(SecondaryButton)
