export const styles = theme => ({
  paper: {
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  flow: {
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
    borderRadius: 10,
  },
  button: {
    background: 'var(--color-button-primary)',
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
    borderRadius: 10,
    color: 'var(--color-white)',
    fontFamily: "'Lato', sans-serif",
    '& + button': {
      margin: '0 0 0 10px',
    },
  },
  root: {
    zIndex: 99999,
  },
  disconnectButton: {
    display: 'flex',
    alignItems: 'center',
    color: '#FF6666 !important',
    cursor: 'pointer',
  },
  loaderScreen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 151.5,
    width: 455.5
  },
  '@global': {
    "[class*='MuiDialog-paperWidthSm']": {
      boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
      borderRadius: 10,
    },
    '.buttonHover:hover': {
      background: 'var(--color-white)',
      color: 'var(--color-button-primary)',
    },
    '.buttonHover1:hover': {
      color: 'var(--color-white)',
      background: 'var(--color-button-primary)',
    },
    '.buttonHover1': {
      background: 'var(--color-white)',
      color: 'var(--color-button-primary)',
    },
  },
})
