import React, { FC, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import ConversationsSelector from '../ConversationsSelector'
import Pagination from '../../../../uiKit/Pagination'
import { getConversations } from '../../api/funnels'
import UserInfo from '../UserInfo'
import TranscriptsHeader from '../TranscriptsHeader'
import Messages from '../Messages'

import classes from './styles.module.scss'
import NoChartData from '../../../../uiKit/NoChartData'
import { FunnelType } from '../../../../models/FunnelTypes'

interface Props {
  selectedStepNumber: number
  funnel: FunnelType
  onClose: () => void
  botId: number
  fromDate: string
  toDate: string
}

const Transcripts: FC<Props> = ({ selectedStepNumber, funnel, onClose, botId, fromDate, toDate }) => {
  const initialPage = 1
  const [filter, setFilter] = useState({ value: 'ALL', label: 'All conversations' })
  const [page, setPage] = useState(initialPage)
  const [count, setCount] = useState(0)
  const [conversation, setConversation] = useState<any>(null)
  const [showConversation, setShowConversation] = useState(false)
  const isLast = useMemo(() => funnel.steps.length === selectedStepNumber, [funnel, selectedStepNumber])

  useEffect(() => {
    setConversation(null)
    setShowConversation(false)
    setFilter({ value: 'ALL', label: 'All conversations' })
  }, [selectedStepNumber])

  useEffect(() => {
    handleChangePage(1)
  }, [filter])

  useEffect(() => {
    setPage(initialPage)
    updateConversation(initialPage)
  }, [fromDate, toDate])

  const handleChangePage = (page: number) => {
    setPage(page)
    updateConversation(page)
  }

  const updateConversation = async (page: number) => {
    setShowConversation(false)

    const conversation = await getConversations({
      funnelId: funnel.id,
      stepNumber: selectedStepNumber,
      from: fromDate,
      to: toDate,
      botId,
      page: page - 1,
      filter: filter.value,
    })

    if (conversation?.totalConversations) {
      setConversation(conversation.conversations[0])
      setShowConversation(true)
    }
    setCount(conversation.totalConversations)
  }

  return (
    <div className={classes.container}>
      <TranscriptsHeader onClose={onClose} selectedStepNumber={selectedStepNumber} />
      <div className={classes.navigationContainer}>
        {isLast === false && <ConversationsSelector value={filter} onChange={setFilter} />}
        <Pagination page={page} count={count} onChange={handleChangePage} />
      </div>
      {showConversation && (
        <>
          <UserInfo conversation={conversation} />
          {conversation && <Messages conversation={conversation} />}
        </>
      )}
      {count === 0 && <NoChartData text="No conversations" />}
    </div>
  )
}

const mapStateToProps = (state: { funnel: FunnelType; activeBot: { id: any } }) => ({
  funnel: state.funnel,
  botId: state.activeBot.id,
})

export default connect(mapStateToProps)(Transcripts)
