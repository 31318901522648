import React from 'react'

import { useFacebookActivePagesContext } from '../FacebookActivePages/context'

import { FacebookActivePagesTableHead } from './FacebookActivePagesTableHead'
import { FacebookActivePagesTableBody } from './FacebookActivePagesTableBody'

import { PaginatedTable } from 'uiKit/PaginatedTable'
import EmptyScreen from '../EmptyScreen'

interface Props {
  classes: any
  botId: number
  getConnectButton: () => void
}

export const FacebookActivePagesTable: React.FC<Props> = props => {
  const { classes } = props
  const { pages } = useFacebookActivePagesContext()

  return pages?.length ? (
    <PaginatedTable colSpan={5} data={pages} className={classes.table}>
      <FacebookActivePagesTableHead />
      <FacebookActivePagesTableBody botId={props.botId} />
    </PaginatedTable>
  ) : (
    <EmptyScreen />
  )
}
