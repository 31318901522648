import React, { FC } from 'react'

import Tooltip from '../../../../uiKit/TooltipInfo/Tooltip'
import SecondaryButton from '../../../../uiKit/buttons/SecondaryButton/SecondaryButton'

import classes from './styles.module.scss'

interface Props {
  onOpen: () => void
}

const tooltipText =
  // eslint-disable-next-line max-len
  'Funnels show how successfully users are navigating a defined flow. A funnel is a series of steps (received atoms) a user completes while interacting with the bot.'

const FunnelsHeader: FC<Props> = ({ onOpen }) => {
  return (
    <div className={classes.header}>
      <div className={classes.headerWrap}>
        <p className={classes.title}>
          Funnels
        </p>
        <Tooltip tooltipId={'TooltipInfo-attribute'} tooltipText={tooltipText} />
      </div>
      <SecondaryButton
        title="Create funnel"
        onClick={onOpen}
        plusIconColor={'var(--color-button-primary)'}
        styles={{ color: 'var(--color-button-primary)', borderColor: 'var(--color-button-primary)' }}/>
    </div>
  )
}

export default FunnelsHeader
