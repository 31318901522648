import React, { useState } from 'react'

import TabTemplate from 'uiKit/TabTemplate'
import { EnterIcon } from 'uiKit/icons/Icons'
import UserSays from '../UserSays'
import { LanguageType } from 'models/LanguageType'
import { UserSayType } from 'models/IntentsType'

import * as S from './UserSaysTab.style'

interface Props {
  language: LanguageType
  expanded: string
  onChange: (a: string) => void
  userSays: UserSayType[]
  setUserSays: (a: UserSayType[]) => void
  trainingText: string
}

export const UserSaysTab: React.FC<Props> = ({ language, expanded, onChange, userSays, setUserSays, trainingText }) => {
  const [text, setText] = useState(trainingText || '')
  const [error, setError] = useState('')
  const [emptySay, setEmptySay] = useState(false)
  const [editInput, setEditInput] = useState(false)

  const getTabInfo = () => {
    return {
      name: language.fullName,
      phrasesNumber: userSays?.length && userSays.find(elem => elem.shortName === language.shortName).phrases.length,
    }
  }

  const handleAddUserSays = () => {
    const currentUserSays = [...userSays]?.find(elem => elem.shortName === language.shortName)?.phrases || []
    const isNotUnique = currentUserSays.some(elem => elem.trim().toUpperCase() === text.trim().toUpperCase())

    if (isNotUnique) {
      setError(text)
    } else if (text !== '') {
      const newUserSays = [...userSays].map(elem => {
        if (elem.shortName === language.shortName) {
          elem.phrases.unshift(text)
          elem.isEdited = true
        }
        return elem
      })

      setUserSays(newUserSays)
      setError('')
      setText('')
    }
  }

  const handleEditUserPhrase = (index, event) => {
    const currentUserSays = [...userSays].find(elem => elem.shortName === language.shortName).phrases

    const isEdit = currentUserSays.some((elem, i) => {
      const isEditPhrase = elem.toUpperCase() === event.target.value.trim().toUpperCase() && index !== i
      if (isEditPhrase) {
        setError(event.target.value)
        setEditInput(index)
      }
      return isEditPhrase
    })

    if (!isEdit && event.target.value !== '') {
      const newUserSays = [...userSays].map(elem => {
        if (elem.shortName === language.shortName) {
          elem.phrases.splice(index, 1, event.target.value)
          elem.isEdited = true
        }
        return elem
      })
      setUserSays(newUserSays)
      setError('')
      setEditInput(null)
    }
  }

  const handleDeleteUserPhrase = index => {
    const newUserSays = [...userSays]

    newUserSays[language.shortName] = newUserSays
      .find(elem => elem.shortName === language.shortName)
      .phrases.splice(index, 1)

    setUserSays(newUserSays)
    setEditInput(null)
  }

  return (
    <TabTemplate tab={getTabInfo()} expanded={expanded === language.shortName} onChange={onChange}>
      <S.InputHolder>
        <S.Input
          maxLength={255}
          value={text || ''}
          dir={language.isRtl ? 'rtl' : 'ltr'}
          placeholder="+ Add user phrase and press Enter to add it"
          onChange={e => setText(e.target.value)}
          onKeyPress={e => e.charCode === 13 && handleAddUserSays()}
        />
        <S.Enter onClick={handleAddUserSays}>
          <EnterIcon width={14} height={14} color="var(--color-malachite-green)" />
        </S.Enter>
        {error && <S.Alert>{`User Phrase '${error}' already exist`}</S.Alert>}
      </S.InputHolder>
      <S.UserSaysWrapper>
        {userSays
          ?.find(elem => elem.shortName === language.shortName)
          ?.phrases.map((says, id) => (
            <div key={says}>
              <UserSays
                says={says}
                isRtl={language.isRtl}
                emptySay={emptySay}
                editInput={editInput}
                modifyInput={setEditInput}
                setEmptySay={setEmptySay}
                editUserPhrase={handleEditUserPhrase}
                deleteUserPhrase={handleDeleteUserPhrase}
                index={id}
              />
            </div>
          ))}
      </S.UserSaysWrapper>
    </TabTemplate>
  )
}
