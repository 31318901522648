import React from 'react'
import { connect } from 'react-redux'

import DownloadButton from '../../../../uiKit/buttons/DownloadButton'
import { ByTimeOfDayHeaders } from '../../../../constants/byTimeOfDayHeaders'

interface Props {
  popularHoursStatistics: any
}

const convertHourlyChartDataCsv = data => data.averageConversationByTimeOfDay

const HourlyChartButton: React.FC<Props> = ({ popularHoursStatistics: hoursChartData }) => {
  return (
    <DownloadButton
      csvData={hoursChartData}
      csvFilename={'average_conversations_by_time_of_day'}
      csvHeaders={ByTimeOfDayHeaders}
      csvOnDownload={convertHourlyChartDataCsv}
    />
  )
}

const mapStateToProps = state => ({
  popularHoursStatistics: state.popularHoursStatistics,
})

export default connect(mapStateToProps)(HourlyChartButton)
