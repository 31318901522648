export const frontDataProcessing = (frontValue = '', prevAttrIndex = 0) => {
  const attrIndex = frontValue?.indexOf('<attr', prevAttrIndex)
  const attrBackIndex = frontValue?.indexOf('>', attrIndex)

  if (attrIndex < 0 || attrBackIndex < 0) {
    return frontValue
      .replaceAll('<br>', '\n')
      .replaceAll('&nbsp;', ' ')
      .replaceAll('&gt;', '>')
  }

  let newArr = frontValue.split('')
  newArr.splice(attrIndex, attrBackIndex - attrIndex + 1, '{', '{')
  frontValue = newArr.join('')
  const endAttrIndex = frontValue.indexOf('</attr>', attrIndex)
  newArr = frontValue.split('')
  newArr.splice(endAttrIndex, 7, '}', '}')
  frontValue = newArr.join('')

  if (frontValue.includes('<attr') || frontValue.includes('</attr>')) {
    return frontDataProcessing(frontValue, attrIndex + 4)
  } else {
    return frontValue
      .replaceAll('<br>', '\n')
      .replaceAll('&nbsp;', ' ')
      .replaceAll('&gt;', '>')
  }
}
