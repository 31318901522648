import styled from 'styled-components'

const ExternalLinkWrap = styled.div`
  cursor: pointer;
`

const SelectorWrap = styled.span`
  width: 290px;
`

export { ExternalLinkWrap, SelectorWrap }
