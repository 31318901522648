import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: '30%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.14)',
    padding: theme.spacing.unit * 4,
    overflowY: 'auto',
    borderRadius: 10,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  statesModal: {
    height: 45,
    padding: '10px 0',
  },
  heading: {
    lineHeight: 'normal',
    fontSize: 18,
    color: 'var(--color-text-primary)',
    textAlign: 'center',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    flexBasis: '60%',
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  input: {
    padding: '10px',
    border: 'none',
    outline: 'none',
    width: '100%',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'var(--color-white)',
  },
  inputHolder: {
    border: '1px solid var(--color-ghost-grey)',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'var(--color-white)',
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
    minWidth: '100%',
  },
  confirm: {
    padding: '12px 28px',
    border: 0,
    background: 'var(--color-button-primary)',
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
    borderRadius: 10,
    cursor: 'pointer',
    color: 'var(--color-text-on-primary)',
    margin: '30px auto 0',
    display: 'inline-flex',
  },
  cancel: {
    padding: '12px 28px',
    border: 0,
    background: 'transparent',
    outlineColor: 'var(--color-button-primary)',
    borderRadius: 10,
    cursor: 'pointer',
    color: 'var(--color-button-primary)',
    margin: '30px auto 0',
    display: 'inline-flex',
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
  },
  modalHeading: {
    fontSize: 16,
    color: 'var(--color-text-primary)',
    fontWeight: 600,
  },
  '@global': {
    '.addPhrase::placeholder,.addPhrase': {
      color: 'var(--color-text-primary)',
    },
    '.Select-placeholder': {
      lineHeight: '43px',
    },
    '.Select-input > input': {
      lineHeight: '28px',
    },
    '.Select-value': {
      paddingTop: 4,
    },
    '.Select-control': {
      height: 45,
    },
    '::-webkit-scrollbar': {
      width: 12,
    },

    '::-webkit-scrollbar-track': {
      /*-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);*/
      borderRadius: 3,
    },

    '::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      webkitBoxShadow: 'inset 0 0 6px rgba(24, 24, 24, 0.5)',
      backgroundColor: 'rgba(99, 99, 99, 0.2)',
    },
  },
})

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { classes, itemId, itemTitle, confirmAction, closeModal } = this.props
    return (
      <div className={classes.paper} tabIndex={1}>
        <p>Are you sure you want to delete this item?</p>
        <h5>{itemTitle}</h5>
        <div className={classes.btnContainer}>
          <button
            className={classes.confirm}
            onClick={() => {
              confirmAction(itemId)
              closeModal()
            }}>
            Confirm
          </button>
          <button className={classes.cancel} onClick={closeModal}>
            Cancel
          </button>
        </div>
      </div>
    )
  }
}

ConfirmModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(ConfirmModal))
