/**
 * Created by serhiy on 12.03.18.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { createTag } from '../../api/tag'
import { alertError } from '../../../../api'
import InputHolder from '../../../../uiKit/inputs/InputHolder'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'

const styles = theme => ({
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
  },
})

class TagModal extends React.Component {
  changeName = e => {
    this.setState({ tagValue: e.target.value })
  }

  createTag = () => {
    if (
      !this.props.allTags
        .map(function(tag) {
          return tag.value
        })
        .includes(this.state.tagValue)
    ) {
      createTag(
        { value: this.state.tagValue },
        this.props.match.params.botId,
        this.props.closeTagModal,
      )
    } else {
      alertError('Tag with this name already exists')
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      tagValue: '',
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <InputHolder
          placeholder="Type tag name"
          maxLength={90}
          holderStyle={{
            margin: '40px auto',
          }}
          defaultValue={this.state.tagValue}
          onChange={e => this.changeName(e)}
        />
        <div className={classes.buttonHolder}>
          <SubmitButton onClick={() => this.createTag()} title="Create" type={'secondary'} />
        </div>
      </div>
    )
  }
}

TagModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles)(TagModal))
