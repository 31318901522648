import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { styles } from './styles'
import ReactTooltip from 'react-tooltip'
import { ExternalLinkIcon } from '../../../../../../uiKit/icons/ExternalLinkIcon'
import { DoneIcon } from '../../../../../../uiKit/icons/Icons'

interface Props {
  innerProps: any
  classes: any
  innerRef: any
  data: any
  getStyles: (option: string, props: Props) => any
}

const CustomOption: React.FC<Props> = props => {
  const { classes, innerProps, innerRef, data } = props
  const selectOptionStyle = props.getStyles('option', props)
  const customSelectStyle = {
    ...selectOptionStyle,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }

  const imageStyle = {
    width: 26,
    height: 26,
    margin: '6px 0',
    display: 'block',
    borderRadius: '50%',
    overflow: 'hidden',
  }

  const handleOpenLink = () => {
    const link = `https://www.facebook.com/${data.value}`
    window.open(link, '_blank')
  }

  return (
    <div ref={innerRef} style={customSelectStyle} {...innerProps}>
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        <img src={data.img} alt="page image" style={imageStyle} />
        <span style={{ width: 190 }}>{data.label}</span>
      </div>
      <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
        {data.isSubscribed && !data.isSubscribedToCurrentPage && (
          <>
            <img
              src={'/images/platform/connected-fb-page.svg'}
              alt="platform icon"
              data-tip
              data-for={`tooltip-connected-page-${data.value}`}
              style={{ cursor: 'pointer' }}
            />
            <ReactTooltip
              className={classes.connectedPageTooltip}
              place="bottom"
              type="light"
              effect="solid"
              id={`tooltip-connected-page-${data.value}`}>
              The page is connected to another bot
            </ReactTooltip>
          </>
        )}
        {data.isSubscribedToCurrentPage && (
          <>
            <div data-tip data-for={`tooltip-connected-page-${data.value}`} style={{ cursor: 'pointer' }}>
              <DoneIcon />
            </div>
            <ReactTooltip
              className={classes.connectedPageTooltip}
              place="bottom"
              type="light"
              effect="solid"
              id={`tooltip-connected-page-${data.value}`}>
              The page is connected to the current bot
            </ReactTooltip>
          </>
        )}
        <div onClick={handleOpenLink}>
          <ExternalLinkIcon />
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(CustomOption)
