import React from 'react'

import { DoneIcon } from 'uiKit/icons/Icons'
import * as S from './TrainButton.style'

interface Props {
  onClick: () => void
  isTrained: boolean
  isDefault?: boolean
}

export const TrainButton: React.FC<Props> = ({ onClick, isTrained, isDefault }) => {
  return (
    <S.TrainButton onClick={onClick} isTrained={isTrained} isDefault={isDefault} disabled={isTrained || isDefault}>
      {isTrained ? <DoneIcon /> : 'Train'}
    </S.TrainButton>
  )
}
