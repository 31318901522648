export const createLinkTag = ({ dataHref, dataExtension, innerText, onclick }) => {
  const spanLink = document.createElement('a')
  spanLink.className = 'linkEdited'
  spanLink.setAttribute('data-href', `${dataHref}`)
  spanLink.setAttribute('contenteditable', 'false')
  spanLink.id = '' + Date.now()
  spanLink.setAttribute('data-extension', `${dataExtension}`)
  spanLink.innerText = innerText
  spanLink.onclick = onclick

  return spanLink
}
