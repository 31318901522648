import React from 'react'
import { connect } from 'react-redux'

import DownloadButton from '../../../../uiKit/buttons/DownloadButton'
import { covertHeaders } from '../../constants/covertHeaders'

interface Props {
  supportStatistics: any
}

const convertCircleChartDataCsv = data => {
  return [...[], data]
}

const CircleChartButton: React.FC<Props> = ({ supportStatistics: pieChartData }) => {
  return (
    <DownloadButton
      csvData={pieChartData}
      csvFilename={'conversations_covered'}
      csvHeaders={covertHeaders}
      csvOnDownload={convertCircleChartDataCsv}
    />
  )
}

const mapStateToProps = state => ({
  supportStatistics: state.supportStatistics,
})

export default connect(mapStateToProps)(CircleChartButton)
