export const styles = theme => ({
  container: {
    width: 124,
    height: 36,
    backgroundColor: 'var(--color-button-primary)',
    color: 'var(--color-text-on-primary)',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '0px 12px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
})
