import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import { styles, customStyles } from './styles'
import Select, { components } from 'react-select'

const options = [
  { value: 'IS', label: 'is' },
  { value: 'IS_NOT', label: 'is not' },
  { value: 'GREATER_THAN', label: 'greater than' },
  { value: 'LESS_THAN', label: 'less than' },
  { value: 'CONTAINS', label: 'contains' },
  { value: 'IS_DEFAULT', label: 'is default' },
  { value: 'IS_NOT_DEFAULT', label: 'is not default' },
]

const OperatorSelect = props => {
  const { classes, value, touched, onFocus, onChange, index } = props

  const getValue = value => {
    return options.find(option => option.value === value)
  }

  const Input = props => {
    return (
      <components.Input {...props} data-autotest={`logical-operator-selector-${index}`}/>
    )
  }

  return (
    <div className={classes.container}>
      <Select
        styles={customStyles}
        options={options}
        value={getValue(value)}
        components={{Input}}
        maxMenuHeight={200}
        minMenuHeight={200}
        menuPlacement={'auto'}
        touched={touched}
        onChange={onChange}
        onFocus={onFocus}
      />
    </div>
  )
}
OperatorSelect.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(OperatorSelect)
