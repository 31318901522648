import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { PlusIcon } from '../../../uiKit/icons/Icons.js'
import { createNewFlow } from '../api/flow'
import Modal from '../../../uiKit/Modal'
import { isStringEmpty } from '../../../helpers/isStringEmpty'
import InputHolder from '../../../uiKit/inputs/InputHolder'

const styles = theme => ({
  addFlow: {
    background: theme.tabs.flows.newFlowBackground,
    boxShadow: '4px 4px 29px rgba(19, 69, 186, 0.0855129)',
    borderRadius: 10,
    padding: 15,
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
  },
  newFlowButton: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.tabs.flows.newFlowText,
    margin: '0 0 0 16px',
    fontFamily: 'Lato',
  },
})

class NewFlow extends React.Component {
  state = {
    open: false,
    usedNames: [],
    isNameOk: true,
    name: '',
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  checkEnterPress = event => {
    if (
      event.key === 'Enter' &&
      !isStringEmpty(this.state.name) &&
      !this.state.usedNames.includes(this.state.name)
    ) {
      this.saveNewFLow()
    }
  }

  handleChangeName(text) {
    this.setState({ name: text })
    if (this.state.usedNames.includes(text)) {
      this.setState({ isNameOk: false })
    } else {
      this.setState({ isNameOk: true })
    }
  }

  saveNewFLow() {
    if (
      !isStringEmpty(this.state.name) &&
      !this.state.usedNames.includes(this.state.name)
    ) {
      const newFLow = {
        name: this.state.name,
      }
      createNewFlow(this.props.botId, newFLow)
      this.setState({ open: false, name: '' })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.flows) {
      const names = []
      nextProps.flows.forEach(flow => {
        names.push(flow.name)
      })
      this.setState({ usedNames: names })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <div
          className={classes.addFlow}
          onClick={this.handleOpen}
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
          }}>
          <Button
            variant="fab"
            style={{
              background: 'var(--color-button-secondary)',
              height: 40,
              width: 40,
              boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
            }}
            aria-label="add">
            <PlusIcon width="13" height="13" color={'var(--color-text-on-secondary)'} />
          </Button>
          <p className={classes.newFlowButton}>Add new flow</p>
        </div>
        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          title="Create a new flow">
          <InputHolder
            placeholder="Flow name"
            maxLength={90}
            autofocus={true}
            defaultValue={this.state.name}
            inputStyle={{width: '100%'}}
            holderStyle={{
              color: 'var(--color-text-primary)',
              width: '100%',
              marginRight: 'unset',
              marginTop: '30px',
            }}
            onChange={event => this.handleChangeName(event.target.value)}
            handleEnterPress={this.checkEnterPress}
          />
          <div className={classes.buttonHolder}>
            <Button
              disabled={!this.state.isNameOk}
              color="primary"
              className={classes.button}
              variant="raised"
              size="large"
              style={{
                float: 'right',
                color: 'var(--color-text-on-primary)',
                backgroundColor: 'var(--color-button-primary)'
              }}
              onClick={() => this.saveNewFLow()}>
              CREATE
            </Button>
          </div>
        </Modal>
      </div>
    )
  }
}

NewFlow.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NewFlow)
